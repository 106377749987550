<template>
  <Dialog :header="$t('controlledDischarge.title')" :modal="true" :dismissableMask="true"
          :visible="display" @update:visible="$emit('update:display', $event)" @show="onShow">
    <div class="form">
      <div>
        <h3>Channel</h3>
        <div class="form-row" style="height: 25px">
          <div class="input">
            <label class="on-its-own">{{ $t('channelLength') }}</label>
            <InputNumber
                @focus="$event.target.select()"
                v-model="channelLength"
                :locale="$i18n.locale" :min="0"
                suffix=" m"/>
          </div>
          <Tooltip :text="$t('channelLengthTT')"/>
        </div>
        <div class="form-row">
          <div class="input">
            <label class="on-its-own">{{ $t('catchmentArea') }}</label>
            <InputNumber
                @focus="$event.target.select()"
                v-model="catchmentArea"
                :locale="$i18n.locale" :min="0"
                suffix=" m²"/>
          </div>
          <Tooltip :text="$t('catchmentAreaTT')"/>
        </div>
        <div class="form-row">
          <div class="input">
            <PermeabilitySelector v-model:selected-permeability="permeability"
                                  v-model:permeability-value="permeabilityValue"
                                  v-if="selectedRun.simple"
            />
            <PermeabilitySelector :selected-permeability="irregularPermeability"
                                  :permeability-value="irregularPermeabilityValue"
                                  v-else disabled
            />
          </div>
          <Tooltip :text="$t('selectAFillTT')"/>
        </div>
        <div class="form-row">
          <div class="input">
            <label class="on-its-own">{{ $t('controlledDischarge.free.label') }}</label>
            <InputText
                :model-value="`${freeDischarge.toLocaleString($i18n.locale, {maximumFractionDigits: 1})} l/s`"
                :readonly="true"/>
          </div>
          <Tooltip :text="$t('controlledDischarge.free.tooltip')"/>
        </div>
        <div class="form-row">
          <div class="input">
            <label class="on-its-own">{{ $t('controlledDischarge.label') }}</label>
            <InputNumber
                @focus="$event.target.select()"
                v-model="controlledDischarge"
                suffix=" l/s"
                mode="decimal"
                :locale="$i18n.locale"
                :min="0" :max="Math.round(freeDischarge * 10) / 10"
                :minFractionDigits="1" :maxFractionDigits="1"/>
          </div>
          <Tooltip>
            <i18n-t scope="global" keypath="controlledDischarge.tooltip"><br/></i18n-t>
          </Tooltip>
        </div>
      </div>
      <div class="right-column">
        <h3>Rainfall</h3>
        <div class="form-row">
          <div class="input">
            <label class="on-its-own">{{ $t('rainfall.map') }}</label>
            <Button id="raindrop" @click="$emit('displayRainfallMap')">
              <img :src="require('@/../../drainage/static/images/icons/raindrop.png')" alt="raindrop"
                   id="raindrop-icon">
            </Button>
          </div>
        </div>
        <div class="form-row" v-if="!customRainfall">
          <div class="input">
            <label class="on-its-own">{{ $t('rainfall.location') }}</label>
            <InputText
                :model-value="rainfallTown"
                :readonly="true"
                class="long-input"/>
          </div>
        </div>
        <div class="form-row">
          <div class="input">
            <label class="on-its-own">{{ $t('rainfall.intensity') }}</label>
            <InputNumber v-if="customRainfall"
                @focus="$event.target.select()"
                v-model="rainfallIntensity"
                suffix=" mm/h"
                mode="decimal"
                :locale="$i18n.locale"
                :minFractionDigits="2" :maxFractionDigits="2"/>
            <InputText v-else
                :model-value="`${$n(rainfallIntensity)} mm/h`"
                :readonly="true"/>
          </div>
        </div>
        <div class="form-row">
          <div class="input">
            <label class="on-its-own">{{ $t('rainfall.stormDuration') }}</label>
            <!-- Ideally this would be read only... but the inputs are still intercepted as an InputNumber -->
            <InputNumber v-if="customRainfall"
                @focus="$event.target.select()"
                v-model="stormDuration"
                suffix=" min"
                :locale="$i18n.locale"/>
            <InputText v-else
                :model-value="`${$n(stormDuration)} mim`"
                :readonly="true"/>
          </div>
        </div>
        <div class="form-row">
          <div class="input">
            <label class="on-its-own">{{ $t('rainfall.r5') }}</label>
            <InputNumber v-if="customRainfall"
                @focus="$event.target.select()"
                v-model="r5"
                suffix=" mm/h"
                mode="decimal"
                :locale="$i18n.locale"
                :minFractionDigits="2" :maxFractionDigits="2"/>
            <InputText v-else
                :model-value="`${$n(r5)} mm/h`"
                :readonly="true"/>
          </div>
        </div>
        <div class="form-row">
          <div class="input">
            <label class="on-its-own">{{ $t('rainfall.r15') }}</label>
            <InputNumber v-if="customRainfall"
                @focus="$event.target.select()"
                v-model="r15"
                suffix=" mm/h"
                mode="decimal"
                :locale="$i18n.locale"
                :minFractionDigits="2" :maxFractionDigits="2"/>
            <InputText v-else
                :model-value="`${$n(r15)} mm/h`"
                :readonly="true"/>
          </div>
        </div>
        <div class="form-row">
          <div class="input">
            <label class="on-its-own">{{ $t('rainfall.r60') }}</label>
            <InputNumber v-if="customRainfall"
                @focus="$event.target.select()"
                v-model="r60"
                suffix=" mm/h"
                mode="decimal"
                :locale="$i18n.locale"
                :minFractionDigits="2" :maxFractionDigits="2"/>
            <InputText v-else
                :model-value="`${$n(r60)} mm/h`"
                :readonly="true"/>
          </div>
        </div>
      </div>
    </div>
    <Divider/>
    <div id="results">
      <template v-if="results && !results.flooded">
        <table>
          <tbody>
            <tr>
              <th>{{$t('controlledDischarge.selectedChannel')}}</th>
              <td>{{results.channel_name}}</td>
              <th>{{$t('controlledDischarge.criticalDuration')}}</th>
              <td>{{results.critical_duration_min}} min</td>
            </tr>
            <tr>
              <th>{{$t('controlledDischarge.orificeDiameter')}}</th>
              <td>{{results.orifice_diameter_mm}} mm</td>
              <th>{{$t('controlledDischarge.criticalRainfall')}}</th>
              <td>{{results.critical_rainfall_mm_h}} mm/hr</td>
            </tr>
            <tr>
              <th>{{$t('controlledDischarge.totalChannelVolume')}}</th>
              <td>{{results.channel_capacity_m3}} m<sup>3</sup></td>
              <th>{{$t('controlledDischarge.minimumFreeBoard')}}</th>
              <td>{{results.minimum_free_board_m * 1000}} mm</td>
            </tr>
          </tbody>
        </table>
        <img id="storage-graph" :src="`data:image/png;base64,${results.storage_graph}`"
             alt="Graph of the used channel volume over storm duration">
        <img id="channel-x-section" :src="`data:image/png;base64,${results.x_section_image}`"
             alt="Cross-sectional image of the channel at the deepest water depth">
      </template>
      <p v-else-if="results?.flooded" class="flooded">
        {{ $t('controlledDischarge.flooded') }}
      </p>
    </div>
    <template #footer>
      <Button :label="$t('controlledDischarge.reset')" icon="pi pi-undo" @click="reset"
              v-tooltip.top="$t('controlledDischarge.resetTT')" :disabled="controlledDischarge <= 0 && !results"/>
      <Button :label="$t('controlledDischarge.calculate')" icon="pi pi-cloud" @click="calculate" autofocus
              v-tooltip.top="$t('controlledDischarge.calculateTT')" :disabled="!canCalculate"/>
      <Button :label="$t('controlledDischarge.confirm')" icon="pi pi-check" @click="confirm"
              v-tooltip.top="$t('controlledDischarge.confirmTT')"
              :disabled="results?.flooded || results?.discharge !== controlledDischarge"/>
    </template>
  </Dialog>
</template>

<script>
import {mapGetters} from "vuex";
import PermeabilitySelector from "@/components/main_content/tabs_content/design_run/PermeabilitySelector";
import Tooltip from "@/components/main_content/tabs_content/design_run/Tooltip";
import {BASE_URL} from "@/constants";
import {request as fetch} from "@/auth";
import {Custom} from '@/components/main_content/tabs_content/design_run/PermeabilityUtils';

/**
 * @typedef Results
 * @property {boolean} flooded Whether no channel will work without flooding
 * @property {number} discharge The discharge value the results are for
 * @property {string} channel_name The channel which the water is flowing through
 * @property {number} critical_duration_min The storm length at which the channel has most water in
 * @property {number} orifice_diameter_mm The size of hole the discharge limit needs
 * @property {number} critical_rainfall_mm_h The amount of rain falling when channel has most water in
 * @property {number} channel_capacity_m3 The total capacity of the channel if completely full
 * @property {number} minimum_free_board_m The gap from the deepest depth to the top of the channel
 * @property {string} x_section_image A Base64 encoded image of the channel's cross-section
 * @property {string} storage_graph A Base64 encoded image of the volume used over storm duration
 */

export default {
  name: "ControlledDischarge",
  components: {PermeabilitySelector, Tooltip},
  props: ['display'],
  emits: ['update:display', 'displayRainfallMap'],
  inject: ['doCalculate'],
  data() {
    return {
      controlledDischarge: 0,
      /** @type ?Results */
      results: null,
    };
  },
  computed: {
    ...mapGetters([
       'selectedRun',
    ]),
    channelLength: {
      get() {
        return this.selectedRun.channel_length_m;
      },
      set(channelLength) {
        this.$store.commit('setChannelLength', {
          channelLength,
        });
      }
    },
    catchmentArea: {
      get() {
        return this.selectedRun.drainage_area_m2;
      },
      set(catchmentArea) {
        this.$store.commit('setCatchmentArea', {
          catchmentArea,
        });
      }
    },
    permeability: {
      get() {
        return this.selectedRun.permeability_description;
      },
      set(value) {
        this.$store.commit('setPermeabilityName', value);
      }
    },
    permeabilityValue: {
      get() {
        return this.$store.getters.getPermeability;
      },
      set(permeability) {
        this.$store.commit('setPermeability', {
          permeability,
        });
      }
    },
    irregularPermeability() {
      const areas = Object.values(this.$store.getters.getIrregularAreas);
      const permeabilities = new Set(areas.flatMap(area => area.map(catchment => catchment.permeability)));
      //If there is only one permeability used return that, otherwise it counts as custom
      return permeabilities.size === 1 ? permeabilities.values().next().value : Custom;
    },
    irregularPermeabilityValue() {
      const areas = Object.values(this.$store.getters.getIrregularAreas);
      const permeabilities = new Set(areas.flatMap(area => area.map(catchment => catchment.permeability_value)));
      //If there is only one permeability value return that, otherwise the values need to be averaged
      if (permeabilities.size === 1) return permeabilities.values().next().value;
      let total = 0;
      let weighting = 0;
      for (const area of areas) {
        for (const catchment of area) {
          const weight = (catchment.start_width_m + catchment.end_width_m) * catchment.length_m / 2;
          total += catchment.permeability_value * weight;
          weighting += weight;
        }
      }
      return +(total / weighting).toFixed(2);
    },
    rainfallTown: {
      get() {
        return this.selectedRun.rainfall.town;
      },
      set(town) {
        this.$store.commit('setRainfallIntensity', {
          town,
        });
      }
    },
    rainfallIntensity: {
      /** @returns {number} The rainfall intensity in mm/h */
      get() {
        return this.selectedRun.rainfall.rainfall_mm_per_hr;
      },
      /** @param {number} rainfallIntensity The rainfall intensity in mm/h */
      set(rainfallIntensity) {
        this.$store.commit('setRainfallIntensity', {
          rainfallIntensity,
        });
      }
    },
    stormDuration: {
      /** @returns {number} The storm duration in minutes */
      get() {
        return this.selectedRun.rainfall.storm_duration;
      },
      /** @param {number} stormDuration The storm duration in minutes */
      set(stormDuration) {
        this.$store.commit('setStormDuration', {
          stormDuration,
        });
      }
    },
    r5: {
      get() {
        return this.selectedRun.rainfall.fiveMinuteRate;
      },
      set(rainfallIntensity) {
        this.$store.commit('setRainfallIntensity', {
          rainfallIntensity,
        });
      }
    },
    r15: {
      get() {
        return this.selectedRun.rainfall.fifteenMinuteRate;
      },
      set(rainfallIntensity) {
        this.$store.commit('setRainfallIntensity', {
          rainfallIntensity,
        });
      }
    },
    r60: {
      get() {
        return this.selectedRun.rainfall.sixtyMinuteRate;
      },
      set(rainfallIntensity) {
        this.$store.commit('setRainfallIntensity', {
          rainfallIntensity,
        });
      }
    },
    customRainfall() {
      return this.selectedRun.rainfall.override;
    },
    freeDischarge() {
      //m^2 * (mm/h * 1000) = m^3/h
      //m^3/h / 3600 = m^3/s
      //m^3/s / 1000 = l/s
      return this.catchmentArea * this.rainfallIntensity / 3600; //* 1000 cancels with / 1000
    },
    canCalculate() {
      //Need a channel length and a catchment for the water to flow from and into
      return this.channelLength > 0 && this.catchmentArea > 0
          //Also need the rainfall information, these will get auto-filled by picking a place on the rainfall map
          && this.rainfallIntensity > 0 && this.stormDuration > 0 && this.r5 > 0 && this.r15 > 0 && this.r60 > 0;
    },
  },
  methods: {
    onShow() {
      //Sync with what the store is using
      this.controlledDischarge = this.selectedRun.controlled_outflow;
      this.results = this.selectedRun.controlledDischargeResults;
    },
    reset() {
      const willChange = this.selectedRun.controlled_outflow > 0;
      this.results = null;
      this.$emit('update:display', false);
      this.$store.commit('setControlledDischarge', {
        controlledDischarge: this.controlledDischarge = 0,
      });
      if (willChange && this.selectedRun.isCalculated) {
        this.doCalculate(); //If the run is already calculated, recalculate without the controlled discharge details
      }
    },
    async calculate() {
      //Make sure we don't try calculating with a controlled discharge beyond what is possible
      if (this.controlledDischarge > this.freeDischarge) this.controlledDischarge = Math.round(this.freeDischarge * 10) / 10;

      const response = await fetch(`${BASE_URL}/drainage/calculate_storage/`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          ...this.selectedRun.rainfall,
          channel_length_m: this.channelLength,
          catchment_area_m2: this.catchmentArea,
          permeability: this.permeabilityValue,
          controlled_outflow: this.controlledDischarge,
        }),
      });
      if (response.ok) {
        //Everything is happy, show the results
        this.results = await response.json();
        this.results.discharge = this.controlledDischarge;
      } else {
        //Something went wrong
        this.results = null;
      }
    },
    confirm() {
      this.$store.commit('setControlledDischarge', {
        controlledDischarge: this.controlledDischarge,
        results: this.results,
      });
      this.$emit('update:display', false); //Close the window
      if (this.selectedRun.isCalculated) {
        this.doCalculate(); //If the run is already calculated, recalculate with the new controlled discharge details
      }
    },
  },
};
</script>

<style scoped lang="scss">
h3 {
  margin: 0;
}

.form {
  display: grid;
  column-gap: 1.5rem;
  //Split space evenly between elements
  grid-template-columns: 1fr 1fr;
}

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  column-gap: 5px;
}

.input {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

label {
  margin-right: 0.5rem;
}

table, label, ::v-deep(.p-inputtext) {
  font-size: 0.7rem;
}

::v-deep(.p-inputtext) {
  padding: 0 2px;

  &:not(.long-input) {
    width: 75px;
  }
}

::v-deep(#permeability-value) {
  margin-right: 45px; //The normal width is 30px, so 30 + 45 = 75px
}

.right-column :not(.p-dropdown) > ::v-deep(.p-inputtext) {
  text-align: right;
}

#raindrop {
  outline: none;
  border-radius: 50%;
  text-decoration: none;
  width: 10px;
  display: flex;
  justify-content: center;
  background-color: #ece9e9;
  border: 1px solid lightgrey;
  padding: 4px 12px;

  &-icon {
    width: 15px;
    height: 15px
  }
}

.p-divider.p-divider-horizontal {
  margin: 5px 0;

  &::before {
    border-top-color: black;
  }
}

$storage-graph-width: 520px;
$channel-x-section-width: 270px;
$channel-image-height: 380px;

#dialog {
  width: $storage-graph-width + $channel-x-section-width + 30;
}

#results {
  display: grid;
  grid-template-areas: "table x-section" "graph x-section";
  min-height: $channel-image-height;

  & table {
    grid-area: table;

    & th, td {
      text-align: left;
      padding: 0.5em;
    }
  }

  & #storage-graph {
    grid-area: graph;
    width: $storage-graph-width;
  }

  & #channel-x-section {
    grid-area: x-section;
    width: $channel-x-section-width;
    height: 100%;
    //We just want the left-hand side of the image, without any scaling or stretching
    object-position: left 50%;
    object-fit: none;
  }
}

.flooded {
  color: #e3001b;
  font-size: x-large;
  font-weight: bold;
  text-align: center;
}
</style>