export default {
  "language": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenian"])}
  },
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "load": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Folder - Select a project to load"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share project"])},
    "shareText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To provide access to this project design, copy and paste the link below for sharing with colleagues or customers:\n\n", _interpolate(_named("link")), "\n\nOther parties will need to register for access to the same version of hydraulic design software."])},
    "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete project"])},
    "noProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No saved projects"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing ", "{", "first", "}", " to ", "{", "last", "}", " of ", "{", "totalRecords", "}", " projects"])},
    "progressBar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Loaded 0 runs of ", _interpolate(_named("total"))]), _normalize(["Loaded 1 run of ", _interpolate(_named("total"))]), _normalize(["Loaded ", _interpolate(_named("count")), " runs of ", _interpolate(_named("total"))])])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load project"])}
  },
  "save": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "as-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as new project"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Project"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share project with HAURATON"])},
    "advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAURATON may be able to provide valuable input / advice when checking the design and / or may help achieve the most cost-effective channel configurations through value engineering."])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If an urgent response is required after sharing a project, please contact HAURATON:"])},
    "calculateRuns": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The following runs require calculation before saving: ", _interpolate(_named("runs"))])},
    "needProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project needs a name"])},
    "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expired, try logging in with another tab/window then saving again"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save project..."])}
  },
  "generic_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Cross-section image is generic and used for illustration only"])},
  "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Name"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "projectDetailsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Description"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "calculations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculations"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate"])},
  "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expired, try logging in with another tab/window then calculating again"])},
  "addRunLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Run"])},
  "addRunLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new channel run to current design"])},
  "addNotesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Notes"])},
  "addNotesLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add notes to channel run under design. Notes will feature in the hydraulic data sheets."])},
  "resetLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "resetLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user can return the channel run under design and the Design Parameters window back to the default settings."])},
  "duplicateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
  "duplicateLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a copy of the current channel design"])},
  "catchmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catchment"])},
  "channelsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
  "resultsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
  "designRunsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
  "materialsListLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials List"])},
  "documentsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "selectionOfDrainageSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drainage System"])},
  "selectionOfChannelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Size"])},
  "confirmedLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed Length"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No."])},
  "freeOutflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Outflow"])},
  "outflowInTrashbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outflow Via Trashbox"])},
  "trashboxes": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Trash Box"])},
    "chooseShared": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choose Trash Box (shared with ", _interpolate(_named("shared")), ")"])},
    "withDischarge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Trashbox with ", _interpolate(_named("discharge")), "mm discharge"])},
    "shareWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Share with ", _interpolate(_named("run"))])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No ", _interpolate(_named("loading")), "+ rated trash boxes available for ", _interpolate(_named("type")), " channels"])}
  },
  "endCapWithoutOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Cap With Outlet"])},
  "endcaps": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose End Cap"])},
    "withOutlet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("material")), " ", _interpolate(_named("diameter")), "mm outlet"])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No endcap outlets available for ", _interpolate(_named("channel"))])}
  },
  "modificationLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Discharge Channel"])},
  "noBuildInFall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude Types With Built In Fall"])},
  "headTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head"])},
  "editHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Head"])},
  "editingHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing Head"])},
  "dischargeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discharge"])},
  "editDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Discharge"])},
  "editingDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing Discharge"])},
  "drillingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drill Discharge Channel"])},
  "diameterOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diameter of Hole"])},
  "diameterOfDrillingTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diameter of the hole to be drilled into the channel"])},
  "distanceEdgeToDrillingCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance from the End of Channel to Centre of Hole"])},
  "bottomAxial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottom Axial"])},
  "leftSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left Side Wall"])},
  "rightSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Side Wall"])},
  "cuttingAtFirstElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut Head Channel"])},
  "cuttingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut Discharge Channel"])},
  "lengthFromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length to Remove"])},
  "angleOfCutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angle of Cutting"])},
  "designParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Parameters"])},
  "channelRunLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "channelRunLocationTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location of the channel run / point drain being designed."])},
  "channelLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Length"])},
  "channelLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel running length dimension. Channel run length should be in multiples of 1m. Does not include modular box/chamber dimensions at the start and end of the channel run."])},
  "catchmentDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catchment Depth"])},
  "catchmentDepthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance the catchment area extends from each long side of the channel."])},
  "catchmentArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catchment Area"])},
  "catchmentAreaTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total surface area (m²) that the channel run is required to drain."])},
  "catchment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composite Catchment Area"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catchment"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["'Rectangular' - A rectangular shaped catchment area, symmetrically along both sides of the channel, with a single permeability.", _interpolate(_list(0)), "'Composite' - A catchment area consisting of potentially multiple trapezium shaped sub-areas, each with an independent permeability.", _interpolate(_list(0)), "For further information refer to Designing Irregular Catchment Areas in the HELP file."])},
    "irregular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composite of several sub-areas"])},
    "uniform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rectangular, adjacent to the channel"])},
    "editShape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Catchment Shape"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])}
  },
  "catchmentWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum catchment area exceeded"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The catchment area must be less than 100,000m², please reduce your design by at least ", _interpolate(_named("excess")), "m²"])}
  },
  "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "configurationTT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["'Stepped' - Channel run with a series of deeper channel units introduced towards the outlet.", _interpolate(_list(0)), "'Single' - Channels of the same width and depth interconnected to form the channel run.", _interpolate(_list(0)), "Refer to the HELP file for further information."])},
  "stepped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stepped"])},
  "stepWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concrete channel designs cannot be stepped"])},
    "steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steel channel designs cannot be stepped"])}
  },
  "rainfall": {
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rainfall Map"])},
    "mapTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on raindrop icon for UK Rainfall Map. Select accurate rainfall data for specific locations by further clicking on the UK Rainfall Map. Rainfall intensity values can also be input manually."])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "intensity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rainfall Intensity"])},
    "intensityTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on 'mm/hr' text to change rainfall measurement to litres/second/hectare' (l/(s*ha)) or 'litres/second/m²' (l/s/m²)."])},
    "stormDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storm Duration"])},
    "r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r5"])},
    "r15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r15"])},
    "r60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r60"])},
    "default": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Default Rainfall"])},
      "changeDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to use the selected rainfall as the default for new runs?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
    }
  },
  "calculationMethod": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Method"])},
    "gvf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manning"])},
    "hrw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HRW"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["HAURATON DesignSoftware provides the user with the option to choose between two hydraulic design formula for calculating channel sizes:", _interpolate(_list(0)), "Modified Manning-Strickler (Manning)", _interpolate(_list(0)), "Hydraulics Research Wallingford (HRW).", _interpolate(_list(0)), "Refer to the 'Help' file for further information."])}
  },
  "slopeSetting": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slope Setting"])},
    "useSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slope"])},
    "useElevation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevation"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["'Slope' – Include ground slope as a % value", _interpolate(_list(0)), "'Elevation' – Ground slope calculated using the vertical height change in ground levels at start and end of the channel run."])}
  },
  "longitudinalSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitudinal Slope"])},
  "longitudinalSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This relates to the longitudinal surface ground slope along which the channel is laid (not the slope of the catchment area draining towards the channel run). If the ground is level, insert 0%."])},
  "longitudinalSlopeWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum slope angle exceeded"])},
    "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximum allowable slope for which the hydraulic theory is valid is 1 in ", _interpolate(_named("limit"))])}
  },
  "elevationChange": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevation Change"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The vertical height of the ground at the ends of the run which, along with the channel length, can be used to calculate the longitudinal ground slope", _interpolate(_list(0)), "H = Elevation value at the Head (Start) of the run", _interpolate(_list(0)), "D = Elevation value at the Discharge of the run"])},
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head"])},
    "discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discharge"])}
  },
  "elevationWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum elevation change ratio exceeded"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The maximum allowable slope for which the hydraulic theory is valid is 1 in 30.", _interpolate(_list(0)), "Please reduce your design's elevation change, or increase its length"])}
  },
  "elevationUphillWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel head is below discharge"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["DS3.0 has been designed for channels installed along level ground or mild positive longitudinal gradients.", _interpolate(_list(0)), "Please ensure the elevation change does not result in a negative gradient"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap elevations"])}
  },
  "steppedWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stepped RECYFIX Design"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Hauraton do not offer connection plates between differently sized ", _interpolate(_list(1)), " channels.", _interpolate(_list(0)), "Stepped designs which would require such plates are provided for reference only"])}
  },
  "superCriticalWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important information"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS3.0 has been designed assuming gradually varied flow within linear drainage systems installed along level ground or with mild longitudinal gradients."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For channel runs installed along steeper slopes, the designer should consider that water flow within the channel system may vary between sub-critical and super-critical conditions. This may lead to greater turbulence within the channel body and possible localised surface flooding."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For projects with steeper longitudinal ground slopes, DS3.0 will provide channel designs with sufficient capacity to accommodate the calculated flow but does not take into account rapidly varied flow that may occur within the channel body with resulting consequences."])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For such special situations we advise that project designers use the appropriate methodology and formula to anticipate, predict and accommodate super-critical flow conditions."])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show this again"])}
  },
  "controlledDischarge": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlled Discharge"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlled Discharge"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["HICAP Slot Channel can be used for water storage and attenuation.", _interpolate(_list(0)), "The designer can control the rate of discharge from the channel run.", _interpolate(_list(0)), "The value will display '0' if the flow is unrestricted", _interpolate(_list(0)), "Refer to 'Controlled Discharge' in the HELP file."])},
    "stepWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designs using controlled discharges cannot be stepped or sloped"])},
    "free": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Discharge"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rate at which the water will exit the channel if unrestricted, based on the rainfall intensity and catchment area"])}
    },
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "resetTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset the discharge to be free"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate"])},
    "calculateTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate the result of the limit"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "confirmTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the controlled discharge flow for the current run"])},
    "flooded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No channel could be designed to fulfil the current parameters"])},
    "selectedChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Channel"])},
    "criticalDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical Duration"])},
    "orificeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orifice Diameter"])},
    "criticalRainfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical Rainfall"])},
    "totalChannelVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Channel Volume"])},
    "minimumFreeBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Free Board"])}
  },
  "minimumChannelSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Channel Size"])},
  "minimumChannelSizeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimum size of channel to use for the current run"])},
  "lengthWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum recommended channel length exceeded"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximum recommended channel length is 500m, please consider reducing your design by at least ", _interpolate(_named("excess")), "m"])}
  },
  "lengthError": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum channel length exceeded"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximum channel length for a ", _interpolate(_named("method")), " design is ", _interpolate(_named("limit")), "m, please reduce your design by at least ", _interpolate(_named("excess")), "m"])}
  },
  "divide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divide"])},
  "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge"])},
  "divide&mergeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For details regarding Divide / Merge drainage areas, refer to HELP file (Designing Irregular Catchment Areas)"])},
  "complexCatchment": {
    "areaNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Number"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
    "startDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Depth"])},
    "endDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Depth"])},
    "surfaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface Area"])},
    "permeability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permeability"])}
  },
  "areaLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Length"])},
  "leftSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left Area"])},
  "rightSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Area"])},
  "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single"])},
  "designSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Specification"])},
  "selectedSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected System"])},
  "channelNominalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Nominal Width"])},
  "channelInternalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Internal Width"])},
  "channelInternalDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Internal Depth"])},
  "channelRoughness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roughness"])},
  "channelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Type"])},
  "invertDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invert Depth"])},
  "overallDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall Depth"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "flowVelocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flow Velocity"])},
  "channelDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Discharge"])},
  "floodingRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excess Flood Rate (approx.)"])},
  "dischargeFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discharge Flow"])},
  "maxDischargeFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Possible Discharge"])},
  "usedVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used Volume"])},
  "totalSystemVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total System Volume"])},
  "freeboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeboard"])},
  "installedGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installed Gradient"])},
  "installedGradientTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the channel run is designed with 'stepped' configuration, the difference in heights regarding the channel units included will contribute to the 'installed gradient' of the channel run. In such cases the 'installed gradient' will be greater than the 'longitudinal slope'"])},
  "hydraulicProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydraulic Profile"])},
  "drainageArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drainage Area"])},
  "systemSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Selection"])},
  "switchToAdvanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to Advanced"])},
  "switchToStandard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to Standard"])},
  "selectASystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a System"])},
  "selectAFill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Permeability Factor"])},
  "selectAFillTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impermeability Factor (Run-Off Coefficient) - This value relates to the permeability and texture of the ground surface material, and will determine the volume of water that reaches the channel system after flowing across the surface of the catchment area."])},
  "permeability": {
    "concretePavement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concrete Pavement"])},
    "resinSurface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resin Surface"])},
    "bpCement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Pavers - Cement Joints"])},
    "asphalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asphalt"])},
    "bpSand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Pavers - Sand Filled"])},
    "gravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gravel"])},
    "facade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Façade"])},
    "softLandscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft Landscape"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])}
  },
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "designer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designer"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "materials": {
    "han": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Code"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Description"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "documents": {
    "exportLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Language"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction"])},
    "downloadIntroduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download introduction"])},
    "hydraulicAnalysis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hydraulic Analysis for ", _interpolate(_named("run"))])},
    "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials List"])},
    "downloadMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download materials list"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "downloadInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download information"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Documents"])},
    "downloadAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download all documents"])}
  },
  "switchSide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch Side"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
  "pipeLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pipe Length"])},
  "pipeLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The length of the pipe exiting the point drain / outlet box"])},
  "pipeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pipe Diameter"])},
  "pipeDiameterTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The diameter of the pipe exiting the point drain / outlet box"])},
  "pipeSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pipe Slope"])},
  "pipeSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The angle the pipe exiting the drain is relative to the ground"])},
  "extensionHat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension Body"])},
  "extensionHatTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designer can add an extension piece to point drain by using the toggle button"])},
  "arrowsDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Drag / drop (left click) red circle to add Point Inflow to channel run.", _interpolate(_list(0)), "Drag / drop blue arrow to add Accessory to channel run.", _interpolate(_list(0)), "Right click on red circle / blue arrow to delete from hydraulic profile"])},
  "pointInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point Inflow"])},
  "accessory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessory"])},
  "distanceFromDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance from discharge"])},
  "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depth"])},
  "grating": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grating"])},
    "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select grating"])},
    "slot_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot Dimensions"])},
    "open_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Area"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grating Length"])}
  },
  "positionWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was at least one inflow/accessory positioned beyond the newly specified channel length, therefore they have all been removed. Please add back as desired."])}
  },
  "accessorySwitchLoss": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessories have been removed to accommodate switching to advanced mode. Please add back as desired."])}
  },
  "popups": {
    "inflowDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inflow Details"])},
    "accessoryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessory Details"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
    "directPipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct Pipe"])},
    "flowRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flow Rate"])},
    "connectionMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection Method"])},
    "independent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Independent"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "trashBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trash Box"])},
    "accessBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Box"])},
    "accessCover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Cover"])},
    "endEap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Cap"])},
    "endEapWithOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Cap with Outlet"])},
    "concreteChamber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concrete Chamber"])},
    "noAccessoriesAvailable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no accessories compatible with the chosen position."])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact HAURATON for more information or assistance."])}
    },
    "distanceTooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance from discharge too big"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "webLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Links"])},
  "sharedProjects": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shared Projects"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects shared with Hauraton - Select a project to load"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by"])},
    "saver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last saved by"])},
    "noProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No shared projects"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing ", "{", "first", "}", " to ", "{", "last", "}", " of ", "{", "totalRecords", "}", " projects"])}
  },
  "staffAdmin": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "fact": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fact of the day: ", _interpolate(_named("fact"))])},
    "channelAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Availability"])},
    "hideBlockedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide blocked users"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "allProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Projects"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "isBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
    "deleteUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete User"])},
      "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete user \"", _interpolate(_named("username")), "\" (", _interpolate(_named("name")), ")?"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
    },
    "group": {
      "column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard User"])},
      "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced User"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAURATON Support"])},
      "translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translator"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing User"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
      "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Administrator"])}
    },
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing ", "{", "first", "}", " to ", "{", "last", "}", " of ", "{", "totalRecords", "}", " users"])},
    "userProjects": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Name"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Country"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
      "lastSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last saved"])},
      "saver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last saved by"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date deleted"])},
      "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing ", "{", "first", "}", " to ", "{", "last", "}", " of ", "{", "totalRecords", "}", " projects"])}
    }
  },
  "translator": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Translator"])}
  },
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site only uses essential cookies"])},
  "waterInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Inflow"])},
  "waterInflowTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This function provides the facility to include an additional point inflow within the point drain. Add volume of point inflow in litres / second (l/s)."])},
  "selectChannelFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a channel first."])},
  "lengthDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The channels selected don't match the specified run length."])},
  "auto-cut": {
    "short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The channels selected are ", _interpolate(_named("length")), " m shorter than specified run length."])},
    "long": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The channels selected are ", _interpolate(_named("length")), " m longer than specified run length."])},
    "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This difference can be automatically corrected by cutting the channel at one end short."])},
    "run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce Run Length"])},
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut Head"])},
    "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually Fix"])},
    "discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut Discharge"])}
  },
  "userSettings": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Settings"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "defaultMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default mode"])},
    "standardMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "advancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
    "userRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default rainfall intensity units"])},
    "projectRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project rainfall intensity units"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOG IN"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you already have an account on this site then please login here:"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "forgottenPassword": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Forgotten your password? Click ", _interpolate(_named("here")), " to reset it."])},
    "doNotHaveAccount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Don't have an account? Register ", _interpolate(_named("here")), "."])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])}
  },
  "passwordReset": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESET PASSWORD"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address below and we'll send you a link you can use to reset your password."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "otherwiseLogIn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Otherwise you can login from ", _interpolate(_named("here")), "."])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password:"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password:"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "invalidLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password reset link was invalid, possibly because it has already been used."])},
    "invalidLinkTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please request a new password reset if still required."])}
  },
  "softwareFeatures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOFTWARE FEATURES"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version UK"])},
    "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version Europe/International"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAURATON reserves the right to deny or block access to this program and to inspect any projects created and the calculation history."])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FEEDBACK"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you would like to provide feedback regarding our DesignSoftware please refer to the following:"])},
    "europe&beyond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europe & World"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Kingdom"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US FOR SPECIALISED DESIGN SUPPORT"])}
  },
  "freedomAdvertBox": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FREEDOM TO DESIGN, FREE TO USE"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our new web-based application ‘DesignSoftware’ provides industry professionals with quick, simple hydraulic analysis, channel sizing, project design and product specification for HAURATON’s range of surface drainage systems whilst working on their own desk-top computers, laptops and tablets."])},
    "justThreeClicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just three clicks to a calculation..."])}
  },
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "createAnAccountParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this is your first time using this site, then please create an account below:"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Confirmation"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
  "registrationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration successfully saved"])},
  "checkConfirmationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your email for a confirmation link"])},
  "designReassurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Reassurance"])},
  "reassuranceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DesignSoftware has been tested extensively and the methodology validated by an independent hydraulics consultancy company. Our ‘in-house’ hydraulic design software has been used successfully by HAURATON technical personnel for over 30 years with total reliability."])},
  "confidential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidential"])},
  "confidentialParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered users (password protected) have access to safe and secure server storage regarding their final designs. Designs remain strictly confidential unless users tick to ‘share with HAURATON’. Quick, easy sharing of project designs between registered users for further design or discussion. DesignSoftware has been configured to comply fully with GDPR ‘data privacy’ regulations."])},
  "hydraulicDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydraulic Design - Additional Systems"])},
  "hydraulicDesignParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For hydraulic design of additional HAURATON systems not included within our DesignSoftware, please refer to your HAURATON subsidiary or local trade partner for technical support."])},
  "experienceAndExpertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience & Expertise"])},
  "experienceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A world leader in the manufacture of modular surface drainage systems, HAURATON drainage products have been supplied onto major projects within international markets for over sixty-five years. We developed our first linear drainage system in 1956. Since that time the HAURATON brand has become known around the world as a benchmark for quality, reliability, durability and service."])},
  "help": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HELP"])},
    "topicsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HELP Topics"])},
    "videoTutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Tutorials"])},
    "topic": {
      "about_hauraton_designsoftware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About HAURATON DesignSoftware"])},
      "design_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Method"])},
      "menu_bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu Bar Functions"])},
      "managing_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Projects"])},
      "project_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Information"])},
      "design_functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Functions"])},
      "design_parameters_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Parameters Window "])},
      "irregular_catchment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designing Irregular Catchment Areas"])},
      "rainfall_map_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rainfall Map & Data (UK Version)"])},
      "point_inflows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point Inflows"])},
      "adding_accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Accessory"])},
      "access_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Points"])},
      "advanced_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch To Advanced Designs"])},
      "design_specification_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Specification Window"])},
      "system_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Information"])},
      "recyfix_hicap_slot_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECYFIX HICAP Slot Channel"])},
      "system_installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Installation"])},
      "design_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Support"])},
      "allow_flood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Flooding"])}
    }
  }
}