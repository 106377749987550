<template>
  <div class="main-wrapper">
    <div class="left-wrapper">
      <div style="display: flex; flex-wrap: wrap; justify-content: space-between; row-gap: 1rem; flex-shrink: 0;">
        <ChannelGroupSelector :options="drainageSystems" :placeholder="$t('selectionOfDrainageSystem')"
                              :model-value="selectedDrainageSystem" @update:model-value="selectDrainageSystem"
                              class="dropdown"/>
        <LoadingSelector v-model:selected-loading="selectedLoading" :disabled="!selectedDrainageSystem"
                         v-if="needsLoading" :available-loadings="selectedDrainageSystem?.loading_classes"
                         :optionDisabled="loadingUnavailable" @change="onLoadingChange" class="dropdown"/>
        <div v-else class="dropdown"><!-- Spacing placeholder --></div>
        <Dropdown :model-value="selectedChannelType" :options="channelTypes"
                  v-if="!isHICAP && !isConcrete" :disabled="!selectedDrainageSystem"
                  @update:model-value="selectChannelType" :placeholder="$t('selectionOfChannelType')"
                  class="dropdown"/>
        <div v-else class="dropdown"><!-- Spacing placeholder --></div>
        <Dropdown v-model="selectedGrating" :options="selectedDrainageSystem.gratings"
                  v-if="needsGrating" :disabled="!selectedDrainageSystem"
                  :placeholder="$t('grating.pleaseSelect')" class="dropdown"/>
        <div v-else class="dropdown"><!-- Spacing placeholder --></div>
        <div class="centred-options" v-if="!isConcrete">
          <Checkbox id="no-build-in-fall" v-model="noBuildInFall" :binary="true" @change="handleCheckboxChange()"/>
          <label :for="'no-build-in-fall'">{{ $t('noBuildInFall') }}</label>
        </div>
        <div class="centred-options" style="margin-left: auto;" v-if="!isConcrete">
          <label>{{ $t('confirmedLength') }}</label>
          <InputText type="text" v-model="channelsConfirmedLength" class="confirmed-length" disabled="true"
                     :locale="$i18n.locale" :class="{'wrong-length': lengthsNotMatching}"/>
          <span style="font-weight: bold;">/</span>
          <InputText type="text" v-model="channelLength" class="confirmed-length" disabled="true"
                     :locale="$i18n.locale"/>
        </div>
      </div>
      <div class="table-body" v-if="!isConcrete">
        <table class="datatable">
          <tr>
            <th class="channels-label">Channels</th>
            <th>Length (mm)</th>
            <th>Height (mm)</th>
            <th>Nº</th>
          </tr>
          <tr v-for="channel in channels" :key="channel.name">
            <td class="channels-label" v-if="isHICAP">{{ tweakChannelName(channel) }}</td>
            <td class="channels-label" v-else @click="check_special_channel_count(channel)">{{
                channel.product_type
              }}
            </td>
            <td>{{ channel.channel_length_mm }}</td>
            <td v-if="isHICAP">{{ tweakBodyHeight(channel) }}</td>
            <td v-else>{{ channel.body_height_mm }}</td>
            <td @click="check_special_channel_count(channel)">
              <InputNumber
                  :disabled="channel.body_height_mm.includes(' - ') || channel.name.endsWith('L')"
                  :model-value="channel.number"
                  @focus="$event.target.select()" @input="handleNumberChange(channel, $event)"
                  mode="decimal" :min="0" :step="1" :maxFractionDigits="0"
                  input-class="number" :locale="$i18n.locale"/>
            </td>
          </tr>
        </table>
      </div>
      <div v-else>
        <div class="concrete-input-row">
          <label>{{ $t('channelInternalWidth') }}</label>
          <InputNumber v-model="concreteChannelWidth" @focus="$event.target.select()"
                       mode="decimal" :min="0" :step="1" :maxFractionDigits="0" suffix=" mm" :locale="$i18n.locale"/>
        </div>
        <div class="concrete-input-row">
          <label>{{ $t('channelInternalDepth') }}</label>
          <InputNumber v-model="concreteChannelDepth" @focus="$event.target.select()"
                       mode="decimal" :min="0" :step="1" :maxFractionDigits="0" suffix=" mm" :locale="$i18n.locale"/>
        </div>
        <div class="concrete-input-row">
          <label>{{ $t('channelRoughness') }}</label>
          <InputNumber v-model="concreteChannelRoughness" @focus="$event.target.select()"
                       mode="decimal" :min="0.00001" :step="0.01" :max="0.2" :minFractionDigits="1"
                       :maxFractionDigits="5"
                       suffix=" mm" :locale="$i18n.locale"/>
        </div>
        <div class="concrete-input-row">
          <label>K<sub>st</sub></label>
          <InputNumber v-model="concreteChannelInverseRoughness" @focus="$event.target.select()"
                       mode="decimal" :min="5" :step="1" :max="100000" :maxFractionDigits="1" suffix=" 1/mm"
                       :locale="$i18n.locale"/>
        </div>
        <template v-if="selectedDrainageSystem?.slug === 'steel'">
          <img v-if="$i18n.locale === 'de'" src="@/assets/images/steel-dimensions-de.png" alt="Anschauliches Bild"/>
          <img v-else src="@/assets/images/steel-dimensions.png" alt="Demonstrative image"/>
        </template>
        <template v-else>
          <img v-if="$i18n.locale === 'de'" src="@/assets/images/concrete-dimensions-de.png"
               alt="Anschauliches Bild"/>
          <img v-else src="@/assets/images/concrete-dimensions.png" alt="Demonstrative image"/>
        </template>
      </div>
    </div>
    <div class="right-wrapper">
      <ChannelPreview v-model:editing="editChannelEnd" v-if="!isConcrete"/>
      <template v-if="editChannelEnd === 'discharge' || isConcrete">
        <div class="row" id="radio-buttons">
          <h4>{{ $t('dischargeTitle') }}</h4>
          <div class="wrap">
            <div class="radiobutton ">
              <RadioButton name="dischargeRadios" :disabled="runChannels.length === 0 || isLChannel"
                           v-model="dischargeRadios" value="free-outflow"
                           id="freeOutflow" class="p-button-sm"/>
              <label :for="'freeOutflow'"
                     class="radio-label">{{ $t('freeOutflow') }}</label>
            </div>
            <div class="radiobutton ">
              <RadioButton name="dischargeRadios" :disabled="runChannels.length === 0 || isLChannel || isConcrete"
                           v-model="dischargeRadios" value="outflow-in-trashbox"
                           id="outflowInTrashbox" class="p-button-sm"/>
              <label :for="'outflowInTrashbox'"
                     class="radio-label">{{ $t('outflowInTrashbox') }}</label>
            </div>
          </div>
          <div class="wrap">
            <div class="radiobutton ">
              <RadioButton name="dischargeRadios" :disabled="runChannels.length === 0 || isLChannel || isConcrete"
                           v-model="dischargeRadios" value="end-cap-with-outlet"
                           id="endCapWithoutOutlet" class="p-button-sm"/>
              <label :for="'endCapWithoutOutlet'"
                     class="radio-label">{{ $t('endCapWithoutOutlet') }}</label>
            </div>
            <div class="radiobutton ">
              <RadioButton name="dischargeRadios" :disabled="runChannels.length === 0 || isHICAP || isLChannel"
                           v-model="dischargeRadios" value="modification-last-element"
                           id="modificationLastElement" class="p-button-sm"/>
              <label :for="'modificationLastElement'" class="radio-label">{{
                  $t('modificationLastElement')
                }}</label>
            </div>
          </div>
        </div>
        <Divider class="divider"/>
        <ChannelCutting v-model:enabled="dischargeCutting" v-model:distance="dischargeCuttingDistance"
                      v-model:angle="dischargeCuttingAngle" v-model:leftSide="dischargeCuttingSide"
                      :channel="dischargeChannel" v-if="!isConcrete"/>

        <DischargeModification v-if="dischargeRadios === 'modification-last-element'"/>

        <!-- TRASH BOXES -->
        <Trashboxes v-if="dischargeRadios === 'outflow-in-trashbox'"/>

        <!-- END CAPS -->
        <Endcaps v-if="dischargeRadios === 'end-cap-with-outlet'"/>
      </template>
      <template v-else-if="editChannelEnd === 'head'">
        <h4>{{ $t('headTitle') }}</h4>
        <Divider class="divider"/>
        <ChannelCutting v-model:enabled="headCutting" v-model:distance="headCuttingDistance" :mirrorCut="true"
                        v-model:angle="headCuttingAngle" v-model:leftSide="headCuttingSide" :channel="headChannel"/>
      </template>
    </div>
    <Dialog :header="$t('stepWarning.title')" v-model:visible="steppedWarning" :closable="false">
      {{ $t(`stepWarning.${selectedDrainageSystem?.slug === 'steel' ? 'steel' : 'concrete'}`) }}
      <template #footer>
        <Button :label="$t('ok')" icon="pi pi-check" @click="steppedWarning = false" autofocus/>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {BASE_URL} from "@/constants";
import {request as fetch} from "@/auth";
import {mapGetters} from "vuex";
import ChannelGroupSelector from "@/components/main_content/tabs_content/design_run/ChannelGroupSelector";
import LoadingSelector from "@/components/main_content/tabs_content/design_run/LoadingSelector";
import DischargeModification from "@/components/main_content/tabs_content/design_run/channels/DischargeModification";
import Endcaps from "@/components/main_content/tabs_content/design_run/channels/Endcaps";
import Trashboxes from "@/components/main_content/tabs_content/design_run/channels/Trashboxes";
import ChannelCutting from "@/components/main_content/tabs_content/design_run/channels/ChannelCutting";
import ChannelPreview from "@/components/main_content/tabs_content/design_run/channels/ChannelPreview";

function mapCuttingMethods(type) {
    const baseGetter = `get${type[0].toUpperCase()}${type.substring(1)}Cutting`;
    const hasser = 'has' + baseGetter.substring(3);
    const baseSetter = 's' + baseGetter.substring(1);
    return Object.fromEntries(['Distance', 'Angle', 'Side'].map(prop => {
        const getter = baseGetter + prop;
        const setter = baseSetter + prop;
        return [`${type}Cutting${prop}`, {
            get() {
                // getHeadCuttingDistance, getHeadCuttingAngle, getHeadCuttingSide
                // getDischargeCuttingDistance, getDischargeCuttingAngle, getDischargeCuttingSide
                return this.$store.getters[getter];
            },
            set(value) {
                // setHeadCuttingDistance, setHeadCuttingAngle, setHeadCuttingSide
                // setDischargeCuttingDistance, setDischargeCuttingAngle, setDischargeCuttingSide
                this.$store.commit(setter, value);
            }
        }];
    }).concat([[`${type}Cutting`, {
        get() {// hasHeadCutting, hasDischargeCutting
            return this.$store.getters[hasser];
        },
        set(enabled) {// setHeadCutting, setDischargeCutting
            this.$store.commit(baseSetter, enabled);
        }
    }]]));
}

export default {
  name: "Channels",
  components: {
      ChannelCutting, ChannelPreview, DischargeModification, Trashboxes, Endcaps, ChannelGroupSelector, LoadingSelector,
  },
  setup() {
    return {}
  },
  data() {
    return {
      editChannelEnd: 'discharge',
      steppedWarning: false,
    }
  },
  computed: {
    ...mapGetters([
      'needsLoading',
      'needsGrating',
      'wasAdvancedCalculation',
    ]),
    ...mapGetters({
      drainageSystems: 'advancedDrainageSystems',
      runChannels: 'getRunChannels',
      channelLength: 'getChannelLength',
      channelsConfirmedLength: 'getChannelsConfirmedLength',
      hasCalculated: 'getIsCalculated',
    }),
    lengthsNotMatching() {
      return this.$store.getters.getErrors.lengthsNotMatching
    },
    selectedDrainageSystem: {
      get() {
        return this.$store.getters.getDrainageSystem
      },
      set(value) {
        this.$store.commit('setDrainageSystem', value)
      }
    },
    allChannelTypes: {
      get() {
        return this.$store.getters.getChannelTypes;
      },
      set(value) {
        this.$store.commit('setChannelTypes', value);
      }
    },
    channelTypes() {
      if (!this.selectedLoading) {
        return Object.keys(this.allChannelTypes);
      } else {
        const loading = this.selectedLoading.charAt(0);
        return Object.entries(this.allChannelTypes).filter(([, loadings]) => {
          return loadings.includes(loading);
        }).map(([type,]) => type);
      }
    },
    selectedChannelType: {
      get() {
        return this.$store.getters.getSelectedChannelType
      },
      set(value) {
        this.$store.commit('setSelectedChannelType', value)
      }
    },
    channels: {
      get() {
        return this.$store.getters.getChannelChoices;
      },
      set(value) {
        this.$store.commit('setChannelChoices', value);
      }
    },
    selectedLoading: {
      get() {
        return this.$store.getters.getLoading;
      },
      set(value) {
        this.$store.commit('setLoading', value);
      }
    },
    selectedGrating: {
      get() {
        return this.$store.getters.getGrating;
      },
      set(value) {
        this.$store.commit('setGrating', value);
      }
    },
    noBuildInFall: {
      get() {
        return this.$store.getters.getNoBuildInFall;
      },
      set(value) {
        this.$store.commit('setNoBuildInFall', value);
      }
    },
    isHICAP() {
      return this.selectedDrainageSystem?.slug === 'recyfixhicap';
    },
    isLChannel() {
      return this.headChannel?.name?.endsWith('L');
    },
    isConcrete() {
      return this.selectedDrainageSystem?.product_type === 'M';
    },
    concreteChannelWidth: {
      get() {
        return this.$store.getters.selectedRun.concreteChannel.width;
      },
      set(width) {
        this.$store.commit('updateConcreteChannelUse', {
          width,
        });
      }
    },
    concreteChannelDepth: {
      get() {
        return this.$store.getters.selectedRun.concreteChannel.depth;
      },
      set(depth) {
        this.$store.commit('updateConcreteChannelUse', {
          depth,
        });
      }
    },
    concreteChannelRoughness: {
      get() {
        return this.$store.getters.selectedRun.concreteChannel.roughness;
      },
      set(roughness) {
        this.$store.commit('updateConcreteChannelUse', {
          roughness,
        });
      }
    },
    concreteChannelInverseRoughness: {
      get() {
        return 1 / this.concreteChannelRoughness;
      },
      set(roughness) {
        this.concreteChannelRoughness = 1 / roughness;
      }
    },
    dischargeRadios: {
      get() {
        return this.$store.getters.dischargeSetting;
      },
      set(setting) {
        this.$store.commit('setDischargeSetting', setting);
      }
    },
    headChannel() {
      let out, outIndex;
      for (const channel of this.runChannels) {
        const index = this.channels.indexOf(channel);
        if (!out || outIndex > index) {
          out = channel;
          outIndex = index;
        }
      }
      return out;
    },
    ...mapCuttingMethods('head'),
    dischargeChannel() {
      let out, outIndex;
      for (const channel of this.runChannels) {
        const index = this.channels.indexOf(channel);
        if (!out || outIndex < index) {
          out = channel;
          outIndex = index;
        }
      }
      return out;
    },
    ...mapCuttingMethods('discharge'),
  },
  watch: {
    dischargeChannel(channel) {
      if (!channel) this.dischargeCutting = false;
      else if (this.dischargeCuttingDistance > channel.channel_length_mm) {
        this.dischargeCuttingDistance = channel.channel_length_mm
      }
    },
    headChannel(channel) {
      if (!channel) this.$store.commit('setHeadCutting', false);
      else if (this.headCuttingDistance > channel.channel_length_mm) {
        this.headCuttingDistance = channel.channel_length_mm
      }
    },
  },
  methods: {
    selectDrainageSystem(productGroup) {
      if (this.selectedDrainageSystem?.name === productGroup?.name) return;

      this.$store.commit('clearChannelUse');
      this.selectedDrainageSystem = productGroup;

      if (!this.isConcrete) {
        if (!productGroup.loading_classes.includes(this.selectedLoading)) {
          // Pick the biggest loading as a default, if the current loading isn't an option
          this.selectedLoading = productGroup.loading_classes.reduce((maxLoading, loading) => {
            return loading.substring(1) > maxLoading.substring(1) ? loading : maxLoading;
          });
        }

        //Fill the subproduct dropdown
        this.updateChannelTypes();

        if (this.calculationMethod !== 'GVF' && productGroup.slug !== 'recyfixhicap') {
          this.calculationMethod = 'GVF'; //Only HICAPs can change calculation method
        }
      } else if (this.$store.getters.selectedRun.stepped) {
        this.steppedWarning = true;
        this.$store.commit('setStepped', {isStepped: false});
      } else {
        this.$store.commit('updateConcreteChannelUse', {}); //Add the concrete channel to the channels list, allows freely switching between Steel and Concrete
      }
    },
    selectChannelType(channelType) {
      // console.log("CHNNALE on 325", this.selectedDrainageSystem, channelType)
      if (this.selectedChannelType === channelType) return;

      this.$store.commit('clearChannelUse'); //Only one type of channel can be used, so clear any used
      this.selectedChannelType = channelType;
      if (this.selectedDrainageSystem && channelType) {
        // As soon as we have the sub product, fetch related channels
        return this.updateChannels();
      }
    },
    check_special_channel_count(ch) {
      // we can only have one of each  channel type with a built-in slope so toggle the value
      // we can also only have one 'type L' channel.  This isn't robust enough for all circumstances but it's better than nothing
      if (!ch.body_height_mm.includes(' - ') && !ch.name.endsWith('L')) return;
      switch (ch.number) {
        case 0:
          ch.number = 1;
          break;
        default:
          console.warn('Unexpected special channel count', ch.number, ch);
          // eslint-disable-next-line no-fallthrough
        case 1:
          ch.number = 0;
          break;
      }
      if (ch.name.endsWith('L')) {
        out: if (ch.number === 1) {
          // type L channels have a pre-drilled, bottom axial hole at a set distance
          // disable options to change the discharge type, drill at the side or cut the end
          let holeDiameter, holeDistance;
          switch (ch.width_mm) {
            case 100:
              // channel width 100, hole = 110mm, centre is 106 from end
              holeDiameter = 110;
              holeDistance = 106;
              break
            case 150:
              // channel width 150 or 200, hole = 160mm, centre is 131 from end
              holeDiameter = 160;
              holeDistance = 131;
              break
            case 200:
              holeDiameter = 200;
              holeDistance = 152;
              break;
            case 300:
              // channel width > 200, hole = 2000mm, centre is 153 from end
              holeDiameter = 200;
              holeDistance = 153;
              break;
            case 400:
              // channel width > 200, hole = 2000mm, centre is 153 from end
              holeDiameter = 200;
              holeDistance = 153;
              break;
            case 500:
              // channel width > 200, hole = 2000mm, centre is 153 from end
              holeDiameter = 200;
              holeDistance = 153;
              break;
            default:
              // we shouldn't be here!
              console.warn('Unexpected L channel width:', ch.width_mm);
              break out;
          }
          this.dischargeRadios = 'modification-last-element';
          this.$store.commit('setDistanceEdgeToDrillingCenterValue', holeDistance);
          this.$store.commit('setDrillingDiameterValue', holeDiameter);
          this.$store.commit('setDrillingAtLastPosition', 'bottom');
          this.$store.commit('setHasDrillingAtLast', true);
          this.dischargeCutting = false;
        } else {
          this.dischargeRadios = 'free-outflow';
          this.$store.commit('setHasDrillingAtLast', false);
        }
      }
      // nudge the counter function
      this.handleNumberChange(ch, {value: ch.number});
    },
    loadingUnavailable(loading) {
      return this.selectedChannelType && !this.allChannelTypes[this.selectedChannelType].includes(loading.charAt(0));
    },
    onLoadingChange() {
      if (this.selectedDrainageSystem?.name === 'FASERFIX BIG BLS') {
        //Big BLS channels are offered according to load class, so we'll have to adjust the offering whenever the loading changes
        this.$store.commit('clearChannelUse');
        this.updateChannels();
      } else if (this.selectedDrainageSystem?.name === 'FASERFIX SLOTTED CHANNEL' && (!this.channels.length ||
          (this.selectedLoading !== 'E 600') !== this.channels.some(channel => channel.slug.startsWith('faserfix-ks-')))
      ) {
        //Faserfix Slotted channels also change based on load class, but only switching to/from E 600
        this.$store.commit('clearChannelUse');
        const previousChannelType = this.selectedChannelType;
        this.updateChannelTypes().then(() => {
          if (this.channelTypes.includes(previousChannelType)) {
            this.selectChannelType(previousChannelType);
          }
        });
      }
    },
    async handleCheckboxChange() {
      if (this.selectedDrainageSystem && this.selectedChannelType) {
        const usedChannels = this.runChannels.reduce((used, channel) => {
          if (channel.number > 0) used.set(channel.slug, channel.number);
          return used;
        }, new Map());

        await this.updateChannels();

        if (usedChannels.size > 0) {
          this.$store.commit('clearChannelUse'); //The previous channels are all gone now

          //Try restoring the used channels, so long as we weren't not using channels which are no longer available
          //(Slower) equivalent: usedChannels.keys().every(slug => this.channels.some(channel => channel.slug === slug))
          if (this.channels.reduce((count, channel) => count + usedChannels.has(channel.slug), 0) === usedChannels.size) {
            const channelMap = new Map(this.channels.map(channel => [channel.slug, channel]));

            for (const [slug, value] of usedChannels) {
              const channel = channelMap.get(slug);
              console.assert(!!channel, `Unable to find ${slug} channel!`, channelMap);
              this.handleNumberChange(channel, {value});
            }
          }
        }
      }
    },
    async updateChannelTypes() {
      // Reset sub-filtering results
      this.channels = [];

      if (!this.isHICAP) {
        this.selectedChannelType = null;
        const response = await fetch(`${BASE_URL}/drainage/channel/subproducts/?product=${this.selectedDrainageSystem.slug}&loading=${this.selectedLoading.charAt(0)}`);
        this.allChannelTypes = await response.json();
      } else {
        this.selectedChannelType = undefined;
        // If we're using HICAP skip dividing into sub products and take the channels directly
        return this.updateChannels();
      }
    },
    async updateChannels() {
      // Send request with or without built in fall filter (the upstream being 0 for no fall)
      let input = `${BASE_URL}/drainage/channel/?product=${this.selectedDrainageSystem.slug}&loading=${this.selectedLoading.charAt(0)}`
      if (!this.isHICAP) input += '&sub_product=' + this.selectedChannelType;
      if (this.noBuildInFall) {
        input += '&upstream=0'
      }
      const response = await fetch(input)
      const parsedResponse = await response.json()
      // Format the data returned
      let channels = parsedResponse.map(c => {
        c.number = 0

        if (!c.product_type) {//HICAPs don't have a product type, so we'll create one
          console.assert(this.isHICAP, 'Unexpected channel missing product type', c);
          c.product_type = c.name.substring(this.selectedDrainageSystem.name.length).trimLeft();
        } else if (!c.product_type.startsWith("Type ")) {
          c.product_type = "Type " + c.product_type;
        }
        return c;
      });

      // BODGE ALERT: if channels are BIG BLS filter based on load rating
      switch (this.selectedDrainageSystem.name) {
        case 'FASERFIX BIG BLS':
          channels = channels.filter(channel => {
            switch (this.selectedLoading) {
              case 'LCN20': // only 030 and 035
                return channel.product_type.includes('03');
              case 'LCN50': // only 430 and 045
                return channel.product_type.includes('04');
              case 'LCN70': // only 060 and 065
                return channel.product_type.includes('06');
              case 'LCN110': // only 070 and 075
                return channel.product_type.includes('07');
              default:
                return true;
            }
          });
          break;
        case 'FASERFIX SLOTTED CHANNEL': {
          const useStandard = this.selectedLoading === 'E 600';
          channels = channels.filter(channel => channel.slug.startsWith('faserfix-ks-') !== useStandard);
          break;
        }
      }

      // Sort by ascending upstream, then downstream, then finally product type
      // 2022-09-14 Sort just on max_downstream_water_depth_mm ?
      // this.channels = channels.sort((a, b) => {
      //   return parseFloat(a.max_downstream_water_depth_mm) - parseFloat(b.max_downstream_water_depth_mm)
      // });

      this.channels = channels.sort((a, b) => {
        const orderDifference = a.display_order - b.display_order;
        if (orderDifference !== 0) return orderDifference;
        const downstreamDifference = parseFloat(a.max_downstream_water_depth_mm) - parseFloat(b.max_downstream_water_depth_mm);
        if (downstreamDifference !== 0) return downstreamDifference
        const upstreamDifference = parseFloat(a.upstream_water_depth_mm || a.max_downstream_water_depth_mm) - parseFloat(b.upstream_water_depth_mm || b.max_downstream_water_depth_mm);
        if (upstreamDifference !== 0) return upstreamDifference;
        return a.product_type.localeCompare(b.product_type);
      });
    },
    tweakChannelName(channel) {
      switch (channel.sub_product) {
        case '10200':
          if (['E 600', 'F 900'].includes(this.selectedLoading)) {
            return channel.product_type.replace('10200', '10300');
          }
          break;
        case '8200':
          if ('F 900' === this.selectedLoading) {
            return channel.product_type.replace('8200', '8300');
          }
          break;
      }
      return channel.product_type;
    },
    tweakBodyHeight(channel) {
      let throat = 200;
      switch (channel.sub_product) {
        case '10200':
          if (['E 600', 'F 900'].includes(this.selectedLoading)) {
            throat = 300;
          }
          break;
        case '8200':
          if ('F 900' === this.selectedLoading) {
            throat = 300;
          }
          break;
      }
      return +channel.body_height_mm + throat;
    },
    handleNumberChange(channel, {value}, userCausedChange = true) {
      //The channel objects end up living in the store, thus shouldn't really be mutated outside of it
      this.$store.commit('updateChannelUse', {
        channel, value: value ?? 0,
      })
      if (userCausedChange) this.$store.commit('setHasInputChanged', true);
    },
  },
  async mounted() {
    if (this.selectedDrainageSystem && !this.isConcrete) {
      //If the drainage system was selected in standard mode there won't be any channel types to load back
      //So as long as HICAP isn't selected (which isn't split into types), we'll fetch them now
      if (!this.isHICAP && this.channelTypes.length <= 0) {
        await this.updateChannelTypes();
      } else if (this.isHICAP && this.channels.length <= 0) {
        //If HICAP is selected we'll fetch the channels now instead
        await this.updateChannels();
      }

      //Check if the current run has been calculated (not just designed) in standard mode
      if (this.hasCalculated && !this.wasAdvancedCalculation && this.runChannels.length <= 0) {
        //It has, so we'll need to update the channel type, and pick the channels the design uses
        this.noBuildInFall = true; //Standard mode won't pick channels with built in slopes

        if (!this.isHICAP) {
          //Setting the selected channel type here will load the filtered channel types in as if the user did it
          await this.selectChannelType(this.channelTypes.find(type => type === this.$store.getters.selectedRun.results.channel_sub_product));
        }

        console.assert(this.channels.length > 0 && this.channels.every(channel => channel.number === 0),
            "Expected no channels being used but found", this.channels.filter(channel => channel.number > 0));
        //We have switched from a calculated design in standard mode, note which channels have been used
        const channelMap = new Map(this.channels.map(channel => [channel.slug, channel]));

        for (const segment of this.$store.getters.selectedRun.results.segments) {
          let channelSlug;
          switch (this.selectedDrainageSystem.name) {
            case 'RECYFIX SLOTTED CHANNEL':
              channelSlug = segment.channelDescription.replace('recyfix-slotted-channel', 'recyfix-pro');
              break;

            case 'FASERFIX SLOTTED CHANNEL':
              if (this.selectedLoading === 'E 600') {
                channelSlug = segment.channelDescription.replace('faserfix-slotted-channel', 'faserfix-standard');
              } else {
                channelSlug = segment.channelDescription.replace('faserfix-slotted-channel', 'faserfix-ks');
              }
              break;

            default:
              channelSlug = segment.channelDescription;
              break;
          }
          const channel = channelMap.get(channelSlug);
          console.assert(!!channel, `Unable to find ${channelSlug} channel`, channelMap);
          this.handleNumberChange(channel, {value: channel.number + 1}, false);
        }
        if (this.isHICAP) {//The big HICAPs aren't 1m long, so some adjustment might need to be made
          const channel = channelMap.get('recyfix-hicap-f-10200');
          if (channel && channel.number > 1) {//Rounding can be a little bit troublesome, so convert everything to mm
            const over = Math.floor(Math.ceil((this.channelsConfirmedLength - this.channelLength) * 1000) / channel.channel_length_mm);
            if (over > 0) this.handleNumberChange(channel, {value: channel.number - over}, false);
          }
        }
        if (this.channelsConfirmedLength > this.channelLength) {
          this.dischargeCuttingAngle = 90;
          this.dischargeCuttingDistance = Math.round((this.channelsConfirmedLength - this.channelLength) * 1000);
          this.dischargeCutting = true;
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 2rem;
}

.confirmed-length {
  width: 35px !important;
  margin-left: 0.5rem;
  text-align: right;
}

.confirmed-length:nth-child(2) {
  margin-right: 0.5rem;
}

.centred-options {
  display: flex;
  align-items: center;
}

.dropdown {
  font-size: 0.7rem !important;
  width: 230px;
}

.left-wrapper {
  width: 33rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
}


.datatable {
  //Allow clicking through disabled inputs (i.e. the channel count box for special channels)
  .p-inputnumber > ::v-deep(input[disabled]) {
    pointer-events: none;
  }
}

.p-inputtext.p-component {
  width: 20px;
}

.radio-label {
  margin: 0 0.5rem;
}

.radiobutton {
  width: fit-content;
  display: flex;
  align-items: center;
}

.divider {
  margin: 0.5rem 0.5rem 1rem !important;
}

label[for=no-build-in-fall] {
  margin-left: 0.5rem;
}

label, span, input {
  font-size: 0.7rem !important;
}

input {
  padding: 2px;
}

.concrete-input-row {
  width: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;

  & ::v-deep(input) {
    @extend input;
    width: 60px;
    text-align: right;
  }
}

::v-deep(.p-dropdown-label.p-inputtext) {
  font-size: 0.7rem;
  width: 230px;
}

th {
  text-align: left;
  position: sticky;
  top: 0;
  background-color: white;
}

td, th {
  padding: 0.25rem 0.5rem;
}

.channels-label {
  width: 15rem !important;
}

.table-body {
  max-height: 32rem;
  flex-grow: 1;
  overflow-y: scroll;
  margin-top: 1rem;
}

.wrong-length {
  box-shadow: inset 0 0 0 2px #f00;
}

::v-deep(.number) {
  @extend input;
  width: 2rem;
  text-align: right;
}

.input-line {
  width: 400px;
}

#radio-buttons {
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-content: baseline;
  align-items: center;
  gap: 3rem;

  & h4 {
    margin: 0;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.right-wrapper {
  flex: 1;
  padding-left: 2rem;
}

.radios {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  overflow-y: scroll;
  max-height: 60vh;
  margin-top: 1rem;
}

.p-radiobutton-disabled {
  cursor: default;
}

@media (max-width: 1900px) {
  .right-wrapper {
    padding-left: 2rem;
  }

  .main-wrapper {
  }

  ::v-deep(.table-body) {
    font-size: 0.8rem;
  }

  table {
    width: 20rem;
  }

  .table-body {
    width: 20rem !important;
    max-height: 25rem;
  }

  th, td {
    width: 3rem;
  }
  .channels-label {
    width: 5rem !important;
  }

  .left-wrapper {
    width: 20rem;
  }

  #radio-buttons {
    margin-bottom: 0.75rem;
    gap: 2rem;
  }
}

</style>
