<template>
  <div>
    <div id="input-table">
      <label>{{ $t('projectName') }}</label>
      <InputText type="text" v-model="projectName"/>
      <label>{{ $t('location') }}</label>
      <InputText type="text" v-model="location"/>

      <label>{{ $t('reference') }}</label>
      <InputText type="text" v-model="reference"/>
      <label>{{ $t('date') }}</label>
      <InputText type="text" v-model="date"/>

      <label>{{ $t('company') }}</label>
      <InputText type="text" v-model="company"/>
      <label>{{ $t('designer') }}</label>
      <InputText type="text" v-model="designer"/>

      <label>{{ $t('telephone') }}</label>
      <InputText type="text" v-model="telephone"/>
      <label>{{ $t('email') }}</label>
      <InputText type="text" v-model="email"/>
    </div>
    <div>
      <Dropdown v-model="exportLanguage" :options="$i18n.availableLocales"
                :optionLabel="language => this.$t(`language.${language}`)" :placeholder="$t('documents.exportLanguage')"/>
    </div>
    <table>
      <tbody>
        <tr>
          <td>{{ $t('documents.introduction') }}</td>
          <td>
            <Button :label="$t('documents.downloadIntroduction')" class="p-button-sm" @click="downloadDoc('pdf', 'introduction')">
              <img src="@/assets/images/pdf_icon.svg" alt="PDF Icon"/>
            </Button>
          </td>
          <td v-if="isAdvancedMode">
            <Button :label="$t('documents.downloadIntroduction')" class="p-button-sm" @click="downloadDoc('rtf', 'introduction')">
              <img src="@/assets/images/rtf_icon.svg" alt="RTF Icon"/>
            </Button>
          </td>
        </tr>
        <tr v-for="(run, index) in runs" :key="run.id">
          <td>{{ $t('documents.hydraulicAnalysis', {run: run.name}) }}</td>
          <td>
            <Button :label="$t('documents.hydraulicAnalysis', {run: run.name})" class="p-button-sm" @click="downloadRunDoc('pdf', index)">
              <img src="@/assets/images/pdf_icon.svg" alt="PDF Icon"/>
            </Button>
          </td>
          <td v-if="isAdvancedMode">
            <Button :label="$t('documents.hydraulicAnalysis', {run: run.name})" class="p-button-sm" @click="downloadRunDoc('rtf', index)">
              <img src="@/assets/images/rtf_icon.svg" alt="RTF Icon"/>
            </Button>
          </td>
        </tr>
        <tr>
          <td>{{ $t('documents.materials') }}</td>
          <td>
            <Button :label="$t('documents.downloadMaterials')" class="p-button-sm" @click="downloadDoc('pdf', 'materials')">
              <img src="@/assets/images/pdf_icon.svg" alt="PDF Icon"/>
            </Button>
          </td>
          <td v-if="isAdvancedMode">
            <Button :label="$t('documents.downloadMaterials')" class="p-button-sm" @click="downloadDoc('rtf', 'materials')">
              <img src="@/assets/images/rtf_icon.svg" alt="RTF Icon"/>
            </Button>
          </td>
        </tr>
        <tr>
          <td>{{ $t('documents.information') }}</td>
          <td>
            <Button :label="$t('documents.downloadInformation')" class="p-button-sm" @click="downloadDoc('pdf', 'information')">
              <img src="@/assets/images/pdf_icon.svg" alt="PDF Icon"/>
            </Button>
          </td>
          <td v-if="isAdvancedMode">
            <Button :label="$t('documents.downloadInformation')" class="p-button-sm" @click="downloadDoc('rtf', 'information')">
              <img src="@/assets/images/rtf_icon.svg" alt="RTF Icon"/>
            </Button>
          </td>
        </tr>
        <tr>
          <td>{{ $t('documents.all') }}</td>
          <td>
            <Button :label="$t('documents.downloadAll')" class="p-button-sm" @click="downloadDoc('pdf', 'all')">
              <img src="@/assets/images/pdf_icon.svg" alt="PDF Icon"/>
            </Button>
          </td>
          <td v-if="isAdvancedMode">
            <Button :label="$t('documents.downloadAll')" class="p-button-sm" @click="downloadDoc('rtf', 'all')">
              <img src="@/assets/images/rtf_icon.svg" alt="RTF Icon"/>
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import {mapGetters, mapState} from "vuex";
import {BASE_URL} from "@/constants";
import {request as fetch} from "@/auth";


export default {
  name: "Documents",
  components: {},
  data() {
    return {
      exportLanguage: this.$i18n.locale,
    };
  },
  methods: {
    downloadRunDoc(type, index) {
      this.downloadDoc(type, `hydraulics/${index}`);
    },
    async downloadDoc(type, page) {
      const response = await fetch(`${BASE_URL}/drainage/document/${type}/${this.exportLanguage}/${page}/`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(this.project.getDocumentData()),
      });

      if (response.ok) {
        const blob = await response.blob();

        const url = URL.createObjectURL(blob);
        try {
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${page}.${type}`;

          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } finally {//Make sure to clean up the URL again
          URL.revokeObjectURL(url);
        }
      } else if (response.status === 401) {
        alert(this.$t('save.expiredSession'));
      } else {
        const message = await(response.headers.get('Content-Type')?.startsWith('application/json') ? response.json() : response.text());
        Sentry.captureMessage(`Error creating ${type.toUpperCase()}`, scope => {
          scope.setExtras({
            status: response.status,
            response: message,
            project: this.project,
          });
          scope.addEventProcessor(async event => {
            /**
             * Get the attachment upload URL for the given event
             * @param {DsnComponents} dsn The settings Sentry is running with
             * @param {string} eventId The ID of the event to attach the attachments to
             * @returns {string} The URL which accepts attachments for the given event
             * @see https://web.archive.org/web/20220518035336/https://docs.sentry.io/platforms/javascript/enriching-events/attachments/
             */
            function attachmentUrlFromDsn(dsn, eventId) {
              const { host, path, projectId, port, protocol, user } = dsn;
              return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
                path !== '' ? `/${path}` : ''
              }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=custom-javascript`;
            }

            try {
              const data = new FormData();
              data.append(
                'attachment',
                new Blob([JSON.stringify(this.project.getDocumentData())], {
                  type: 'application/json',
                }),
                'project.json',
              );
              await fetch(attachmentUrlFromDsn(
                Sentry.getCurrentHub().getClient().getDsn(),
                event.event_id
              ), {
                method: 'POST',
                body: data,
              });
            } catch (e) {
              console.error('Project dropped on the way', e); //Can't rethrow this or Sentry might get stuck in a loop
            }
            return event;
          });
          return scope;
        });
        alert(`Failed to create ${type.toUpperCase()}`);
      }
    },
  },
  computed: {
    ...mapState([
        'project'
    ]),
    ...mapGetters([
      'isAdvancedMode',
    ]),
    runs() {
      return this.project.runs.filter(run => run.isCalculated);
    },
    projectName: {
      get() {
        return this.project.details.name;
      },
      set(value) {
        this.$store.commit('setProjectName', value);
      }
    },
    location: {
      get() {
        return this.project.details.location;
      },
      set(value) {
        this.$store.commit('setLocation', value);
      }
    },
    reference: {
      get() {
        return this.project.details.reference;
      },
      set(value) {
        this.$store.commit('setReference', value);
      }
    },
    date: {
      get() {
        return this.project.details.date;
      },
      set(value) {
        this.$store.commit('setDate', value);
      }
    },
    company: {
      get() {
        return this.project.details.company;
      },
      set(value) {
        this.$store.commit('setCompany', value);
      }
    },
    designer: {
      get() {
        return this.project.details.designer;
      },
      set(value) {
        this.$store.commit('setDesigner', value);
      }
    },
    telephone: {
      get() {
        return this.project.details.telephone;
      },
      set(value) {
        this.$store.commit('setTelephone', value);
      }
    },
    email: {
      get() {
        return this.project.details.email;
      },
      set(value) {
        this.$store.commit('setEmail', value);
      }
    },
  },
}
</script>

<style scoped lang="scss">
#input-table {
  width: 750px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(20px, auto);
  gap: 10px 30px;
  padding-bottom: 40px;
}

.p-inputtext {
  padding: 3px;
  font-size: 0.7rem;
}

.p-dropdown, .p-dropdown > ::v-deep(.p-inputtext) {
  width: 150px;
  font-size: 0.7rem;
}

table {
  min-width: 500px;
}

tr {
  padding: 5px 0;
}

button {
  background: none;
  border-color: black !important;
  width: 2.75rem;
  height: 3.25rem;
  padding: 0.25rem 0 !important;

  &:hover {
    background-color: var(--black-20) !important;
  }

  &:active {
    background-color: var(--black-40) !important;
  }
}

img {
  width: 100%;
  height: 100%;
}
</style>