<template>
  <template v-if="trashBoxes.length > 0">
    <h4>{{ $t(sharedWith ? 'trashboxes.chooseShared' : 'trashboxes.choose', {shared: sharedWith}) }}</h4>
    <div class="row radios">
      <div class="radiobutton" v-for="trashBox in trashBoxes" :key="trashBox.slug">
        <RadioButton :id="`trash-boxes-${trashBox.slug}`" name="trash-boxes" :value="trashBox.slug"
                     v-model="selectedTrashBox"/>
        <label :for="`trash-boxes-${trashBox.slug}`" class="radio-label">{{ trashBox.name }}</label>
      </div>
    </div>
  </template>
  <p v-else>{{ $t('trashboxes.none', {loading: selectedLoadingName, type: selectedSystem.name}) }}</p>
</template>

<script>
import {BASE_URL} from "@/constants";
import {request as fetch} from "@/auth";
import {mapGetters} from "vuex";

export default {
  name: "Trashboxes",
  data() {
    return {
      trashBoxes: [],
    };
  },
  computed: {
    ...mapGetters([
       'runs',
       'selectedRun',
    ]),
    ...mapGetters({
      selectedSystem: 'getDrainageSystem',
      selectedChannelType: 'getSelectedChannelType',
      selectedLoadingName: 'getLoading',
    }),
    selectedLoading() {
      return this.selectedLoadingName.charAt(0);
    },
    selectedTrashBox: {
      get() {
        return this.$store.getters.outflowTrashbox;
      },
      set(value) {
        this.$store.commit('setOutflowTrashbox', value);
      }
    },
    sharedWith() {
      return this.runs.find(
          run => run.dischargeSetting === 'outflow-in-trashbox' && run.outflowTrashbox === this.selectedRun.id
      )?.name;
    },
  },
  methods: {
    async updateTrashBoxes(system, type, loading) {
      if (!system) return this.trashBoxes = [];
      let url = `${BASE_URL}/drainage/trashbox/discharges/?product=${system.slug}`;
      if (type) url += '&product_type=' + type;
      if (loading) url += '&loading=' + loading;
      const response = await fetch(url);
      const trashBoxes = await response.json();

      /** All runs which empty into a trashbox */
      const trashBoxRuns = this.runs.filter(run => run.dischargeSetting === 'outflow-in-trashbox');
      /** The ID of all runs which empty into a trashbox */
      const trashBoxRunIDs = new Set(trashBoxRuns.map(run => run.id));
      /** The ID of all runs which either empty into, or are emptied into by, another run */
      const usedTrashBoxes = new Set(trashBoxRuns.flatMap(
          //The current run is filtered here out so that it can empty into another run without that being filtered out
          //Filtering it out from trashBoxRuns would allow a run being emptied into to itself empty into another run
          run => run !== this.selectedRun && trashBoxRunIDs.has(run.outflowTrashbox) ? [run.id, run.outflowTrashbox] : []
      ));
      this.trashBoxes = (!usedTrashBoxes.has(this.selectedRun.id) ? trashBoxRuns : []).reduce((boxes, run) => {
        //Any runs which go into a trashbox which we could share with
        if (run !== this.selectedRun && run.selectedSystem?.slug === this.selectedSystem?.slug
            && run.selectedChannelType === this.selectedChannelType && !usedTrashBoxes.has(run.id)) {
          boxes.push({
            slug: run.id,
            name: this.$t('trashboxes.shareWith', {run: run.name}),
          });
        }

        return boxes;
      }, trashBoxes.map(trashBox => ({
        slug: trashBox,
        name: this.$t('trashboxes.withDischarge', {discharge: trashBox}),
      })));
    },
  },
  watch: {
    selectedSystem(channel) {
      this.updateTrashBoxes(channel, this.selectedChannelType, this.selectedLoading);
    },
    selectedChannelType: {
      immediate: true,
      handler(type) {
        this.updateTrashBoxes(this.selectedSystem, type, this.selectedLoading);
      }
    },
    selectedLoading(loading) {
      this.updateTrashBoxes(this.selectedSystem, this.selectedChannelType, loading);
    },
    selectedRun(current, previous) {
      if (current?.selectedSystem === previous?.selectedSystem &&
          current?.selectedChannelType === previous?.selectedChannelType &&
          current?.loading === previous?.loading) {
        //If switching between runs which have the same details the trashbox list won't refresh
        //Typically this would be fine, unless there are runs sharing a trashbox
        this.updateTrashBoxes(current.selectedSystem, current.selectedChannelType, current.loading);
      }
    },
  },
}
</script>

<style scoped>
h4 {
  margin: 0 0 1rem;
}

.radio-label {
  margin: 0.5rem;
}

.radiobutton {
  margin: 0 29px 0.5rem;
  width: fit-content;
}
</style>