<template>
  <div id="canvas-wrapper">
    <canvas ref="canvas" :width="canvasWidth_px" :height="canvasHeight_px" id="canvas"></canvas>
  </div>
  <Dialog :header="$t('divide')" v-model:visible="isDividing" :style="{width: '25rem'}">
    <div>
      <h4 style="display: inline-block; margin: 0 0 10px;">{{ $t('areaLength') }}:</h4> &nbsp; {{ $n(selectedArea.length_m, {maximumFractionDigits: 1}) }} m
    </div>
    <divide-window :selected-area="selectedArea" :side="selectedSide" :resulting-area="divisionArea"
                   @update-left="divisionArea.leftSplit = $event" @update-right="divisionArea.rightSplit = $event"/>
    <template #footer>
      <Button :label="$t('cancel')" icon="pi pi-times" @click="isDividing = false" class="p-button-text"/>
      <Button :label="$t('confirm')" icon="pi pi-check" @click="handleDivide" autofocus/>
    </template>
  </Dialog>
  <div id="divide-merge-buttons" v-if="!readOnly">
    <Button :label="$t('divide')" class="p-button-sm"
            :disabled="!canDivide" @click="isDividing = true"/>
    <Button :label="$t('merge')" class="p-button-sm"
            :disabled="!canMerge" @click="handleMerge"/>
    <Tooltip :text="$t('divide&mergeTT')"/>
  </div>
  <DataTable :value="[...areas.leftAreas, ...areas.rightAreas]" class="p-datatable-sm"
             v-model:selection="selectedArea" compareSelectionBy="equals" v-if="!readOnly">
    <Column column-key="area-number" :header="$t('complexCatchment.areaNumber')"
            header-style="width: 50px; text-align: center">
      <template #body="{index}">{{ index + 1 }}</template>
    </Column>
    <Column field="length_m" :header="$t('complexCatchment.length')">
      <template #body="{data, field}">
        {{ $n(data[field], {maximumFractionDigits: 1}) }} m
      </template>
    </Column>
    <Column field="start_width_m" :header="$t('complexCatchment.startDepth')">
      <template #body="{data, field}">
        <InputNumber v-model="data[field]" suffix=" m" :min="0" :minFractionDigits="1" :maxFractionDigits="1"
                     :locale="$i18n.locale"
                     @focus="focusArea(data, $event)" @input="onWidthChange(data, 'startWidth', $event)"/>
      </template>
    </Column>
    <Column field="end_width_m" :header="$t('complexCatchment.endDepth')">
      <template #body="{data, field}">
        <InputNumber v-model="data[field]" suffix=" m" :min="0" :minFractionDigits="1" :maxFractionDigits="1"
                     :locale="$i18n.locale"
                     @focus="focusArea(data, $event)" @input="onWidthChange(data, 'endWidth', $event)"/>
      </template>
    </Column>
    <Column column-key="surface-area" :header="$t('complexCatchment.surfaceArea')">
      <template #body="{data}">
        {{ $n((data.start_width_m + data.end_width_m) * data.length_m / 2, {maximumFractionDigits: 1}) }} m²
      </template>
    </Column>
    <Column column-key="permeability" :header="$t('complexCatchment.permeability')">
      <template #body="{data}">
        <div class="permeability-row">
          <PermeabilitySelector v-model:selected-permeability="data.permeability"
                                v-model:permeability-value="data.permeability_value"
                                :disabled="!channelLength_m" @focus="focusArea(data, $event)"
                                @permeabilityChange="(permeability, value) => onPermeabilityChange(data, permeability, value)"
          />
          <Tooltip :text="$t('selectAFillTT')"/>
        </div>
      </template>
    </Column>
  </DataTable>
  <TooSlopyWarning v-model:display="displaySlopeWarning"/>
</template>

<script>
import {fabric} from "fabric";
import {mapGetters} from "vuex";
import DivideWindow from "@/components/main_content/tabs_content/design_run/catchment/DivideWindow";
import PermeabilitySelector from "@/components/main_content/tabs_content/design_run/PermeabilitySelector";
import Tooltip from "@/components/main_content/tabs_content/design_run/Tooltip";
import TooSlopyWarning from "@/components/main_content/tabs_content/design_run/TooSlopyWarning";

/**
 * @typedef {fabric.Polygon} CatchmentArea
 * @property {!string} position The side of the channel this catchment is on
 */

export default {
  name: "IrregularCatchment",
  components: {DivideWindow, PermeabilitySelector, Tooltip, TooSlopyWarning},
  props: ['channelLength_m', 'catchmentArea_sqm', 'readOnly'],
  data() {
    return {
      holdingShift: false,
      isDividing: false,
      canMerge: false,
      divisionArea: {
        leftSplit: 0,
        rightSplit: 0,
      },
      selectedSide: null,
      expectAreaChange: false,
      displaySlopeWarning: false,
      //
      canvasHeight_px: 350,
      channelWidth_px: 10,
      selectedArea: null,
      windowWidth: window.innerWidth
    }
  },
  mounted() {
    const ref = this.$refs.canvas;
    //Don't let canvas be reactive: https://github.com/fabricjs/fabric.js/issues/6680#issuecomment-723663069
    this.canvas = new fabric.Canvas(ref, {
      selectable: !this.readOnly,
      selection: !this.readOnly,
      preserveObjectStacking: true,
    }).on('selection:created', ({target: selected}) => {
      const {position: side, catchmentArea: area} = selected;
      this.selectedSide = side;
      this.selectedArea = area;
      this.canMerge = false;
      if (selected.type === 'activeSelection') this.attachHoverHandler(selected);
    }).on('selection:updated', ({target: selected}) => {
      if (selected.type === 'activeSelection') {
        console.assert(selected.size() > 1, "Selection of only a single shape:", selected);
        selected.hasControls = false;
        selected.hasBorders = false;
        selected.lockMovementX = true;
        selected.lockMovementY = true;
        this.selectedArea = null;

        //The order of selected objects/shapes is based on the order that the user clicks them in
        const sortedAreas = selected.getObjects().map(shape => shape.catchmentArea).sort((a, b) => a.xOrigin - b.xOrigin);
        const expectedLength = sortedAreas.slice(0, -1).reduce((sum, area) => sum + area.length_m, 0);
        const selectedLength = sortedAreas.at(-1).xOrigin - sortedAreas[0].xOrigin;
        //The lengths should be no more granular than 10cm, so avoid float rounding problems by comparing to that
        this.canMerge = Math.round(expectedLength * 10) === Math.round(selectedLength * 10);
        this.attachHoverHandler(selected); //Might have already attached this, but it doesn't matter
      } else {
        const {position: side, catchmentArea: area} = selected;

        this.selectedSide = side;
        this.selectedArea = area;
        this.canMerge = false;
      }
    }).on('selection:cleared', () => {
      this.selectedArea = null;
      this.selectedSide = null;
      this.canMerge = false;
    })
    //If we already have what we need to draw something, let's do it
    if (this.channelLength_m > 0 && this.catchmentArea_sqm > 0) this.populateDrawing();
    ["keydown", "keyup"].forEach(type => document.addEventListener(type, this.shiftWatcher, {passive: true}));
  },
  unmounted() {
    if (this.channelInterval) clearInterval(this.channelInterval);
    ["keydown", "keyup"].forEach(type => document.removeEventListener(type, this.shiftWatcher));
    this.canvas.clear();
    console.assert(this.canvas.getObjects().length === 0, "Failed to clear canvas", this.canvas.getObjects());
    this.canvas = null;
  },
  computed: {
    ...mapGetters([
      'selectedRun',
    ]),
    longitudinalSlope: {
      get() {
        return this.selectedRun.ground_slope;
      },
      set(value) {
        if (value > this.selectedRun.maxSlopeAngle) {
          this.displaySlopeWarning = true;
          value = this.selectedRun.maxSlopeAngle;
        }
        this.$store.commit('setGroundSlope', {
          id: this.selectedRunId,
          groundSlope: value,
        });
      }
    },
    canvasWidth_px() {
      if (this.windowWidth < 1900) {
        // Laptop screen
        return 500
      } else {
        // Desktop screen
        return 1000
      }
    },
    areas() {
      return this.$store.getters.getIrregularAreas
    },
    leftAreaBottomY() {
      return (this.canvasHeight_px / 2) - (this.channelWidth_px / 2) - 1;
    },
    rightAreaTopY() {
      return (this.canvasHeight_px / 2) + (this.channelWidth_px / 2) - 1;
    },
    catchmentMinY() {
      return 0;
    },
    catchmentMaxY() {
      return this.canvasHeight_px;
    },
    scale() {
      const maxHeight_m = Object.values(this.areas).flat().reduce((max, area) => {
        return Math.max(max, area.start_width_m, area.end_width_m);
      }, 0);
      //The total available vertical height (for both sides) is the canvas's height, minus the channel's height
      //Thus we want half that value for the room the tallest individual shape has, so that nothing is cut off
      //As the selection box width is 3 pixels, include space for that too to avoid the box cutting off at the edges
      return Math.min(
          ((this.canvasHeight_px - this.channelWidth_px) / 2 - 3) / maxHeight_m,
          (this.canvasWidth_px - 3) / this.channelLength_m,
      );
    },
    channelLength_px() {
      return this.m_to_px(this.channelLength_m);
    },
    canDivide() {
      return this.selectedArea !== null && this.selectedArea.length_m > 0.1;
    }
  },
  methods: {
    m_to_px(value_m) {
      // Convert from meters to pixels
      return value_m * this.scale
    },
    px_to_m(value_px) {
      // Convert from pixels to meters
      return value_px / this.scale
    },
    shiftWatcher(event) {
      this.holdingShift = event.shiftKey;
    },
    eraseCanvas() {
      this.canvas.remove(...this.canvas.getObjects());
    },
    populateDrawing() {
      this.canvas.discardActiveObject();
      this.eraseCanvas()
      this.drawChannel()
      this.drawLeftAreas()
      this.drawRightAreas()
    },
    drawChannel() {
      const channel = new fabric.Rect({
        id: 'channel',
        left: 0,
        top: (this.canvasHeight_px / 2) - (this.channelWidth_px / 2), // half of the channel width to center it
        width: this.channelLength_px,
        height: this.channelWidth_px,
        fill: 'lightblue',
        selectable: false,
        hoverCursor: 'normal',
        hasBorders: false
      })
      this.canvas.add(channel)
      const flowIndicator = new fabric.Triangle({
        left: channel.left,
        top: channel.top,
        width: this.channelWidth_px,
        height: this.channelWidth_px,
        fill: 'mediumblue',
        selectable: false,
        hoverCursor: 'normal',
      }).rotate(90);
      this.canvas.add(flowIndicator);

      if (this.channelInterval) clearInterval(this.channelInterval);
      //This slightly strange looking logic loops the flow animation without recursion
      const animate = () => {
        flowIndicator.animate('left', this.channelLength_px, {
          onChange: this.canvas.renderAll.bind(this.canvas),
          onComplete: () => {
            flowIndicator.left = 0;
            this.canvas?.renderAll(); //Not strictly necessary, but hides the triangle until the next cycle
          },
          duration: 10000, //Slightly less than the interval timeout to ensure it's always finished
          easing: fabric.util.ease.easeInCubic(),
        });
      };
      animate(); //Avoid waiting the interval timeout for the first animation
      this.channelInterval = setInterval(animate, 10500);
    },
    drawLeftAreas() {
      this.drawArea(this.areas.leftAreas, 'left', a => {
        return [
          // Point 0
          {
            x: 0,
            y: this.leftAreaBottomY - this.m_to_px(a.start_width_m)
          },
          // Point 1
          {
            x: this.m_to_px(a.length_m),
            y: this.leftAreaBottomY - this.m_to_px(a.end_width_m)
          },
          // Point 2
          {
            x: this.m_to_px(a.length_m),
            y: this.leftAreaBottomY
          },
          // Point 3
          {
            x: 0,
            y: this.leftAreaBottomY
          }
        ];
      });
    },
    drawRightAreas() {
      this.drawArea(this.areas.rightAreas, 'right', a => {
        return [
          // Point 0
          {
            x: 0,
            y: this.rightAreaTopY
          },
          // Point 1
          {
            x: this.m_to_px(a.length_m),
            y: this.rightAreaTopY
          },
          // Point 2
          {
            x: this.m_to_px(a.length_m),
            y: this.rightAreaTopY + this.m_to_px(a.end_width_m)
          },
          // Point 3
          {
            x: 0,
            y: this.rightAreaTopY + this.m_to_px(a.start_width_m)
          }
        ].reverse();
      });
    },
    drawArea(areas, side, pointFinder) {
      const drawn = areas.map((area, i) => {
        const points = pointFinder(area);

        const top = Math.min(...points.map(point => point.y));
        const polygon = new fabric.Polygon(points, {
          canvas: this.canvas,
          id: `${side} ${i}`,
          top: top,
          fill: area.permeability.colour,
          strokeWidth: 3,
          objectCaching: false,
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: false,
          lockScalingFlip: true,
          lockSkewingX: true,
          lockSkewingY: true,
          lockRotation: true,
          selectable: !this.readOnly,
          hasBorders: false,
          catchmentArea: area,
          position: side,
          left: this.m_to_px(area.xOrigin),
          //Don't let the active selection change whilst it is being divided
          onSelect: () => this.isDividing,
          onDeselect: () => this.isDividing,
        });
        this.attachHoverHandler(polygon);

        this.bindCornersToObject(polygon);
        polygon.on('selected', () => {
          console.log("Selected area", area);

          if (this.selectedSide !== side) {
            this.canvas.setActiveObject(polygon);
          }

          polygon.stroke = 'blue'; // highlight the area borders
          this.canvas.bringToFront(polygon); //Make sure the borders (and label if there is one) are fully visible
          this.canvas.bringToFront(this.canvas.getObjects('group').find(group => group.id === `${side} ${i} label`));
        }).on('deselected', () => {
          //Remove highlight now we are unselected
          polygon.stroke = 'transparent';
        });
        this.canvas.add(polygon);

        if (!this.readOnly && area.length_m >= 1) {//Don't try cramming in numbers where they won't fit
          const textTop = side === 'right' ? top + 14 : Math.max(...points.map(point => point.y)) - 14 - 14;
          const textLeft = this.m_to_px(area.xOrigin + area.length_m / 2) + 1;
          this.canvas.add(new fabric.Group([
            new fabric.Rect({
              top: textTop - 4,
              left: textLeft,
              originX: 'center',
              width: 22,
              height: 22,
              fill: 'rgb(183, 23, 46, 0.5)',
            }),
            new fabric.Text(`${(side === 'right' ? this.areas.leftAreas.length + 1 : 1) + i}`, {
              top: textTop,
              left: textLeft,
              originX: 'center',
              fill: 'white',
              fontSize: 14,
              fontFamily: 'sans-serif',
            }),
          ], {
            id: `${side} ${i} label`,
            position: side,
            evented: false, //Appear invisible when clicking so the event goes through to the area behind
          }));
        }

        return {length: points[1].x, start: points[0].y, end: points[1].y};
      })
      console.debug(`Drawing ${side} at ${this.scale}x scale`, drawn.map(shape => `${shape.length}px from ${shape.start} to ${shape.end}`))
    },
    /**
     * Set the given shape to have a grab icon when hovering without shift, a copy icon when hovering with shift
     *
     * @param {fabric.Object} polygon The shape to set {@link fabric.Object.hoverCursor hoverCursor} on
     * @return {fabric.Object} The given shape
     */
    attachHoverHandler(polygon) {
      return Object.defineProperty(polygon, 'hoverCursor', {
        configurable: true, //Allow redefining, mainly for group selection where the group might already have this
        enumerable: true, //Probably not necessary, but doesn't hurt
        get: () => {
          return this.holdingShift ? 'copy' : 'grab';
        },
      });
    },
    /**
     * Attach the scaling balls to the given shape's corners
     *
     * @param polygon {CatchmentArea} The shape to attach the {@link fabric.Control}s to
     * @see http://fabricjs.com/custom-controls-polygon
     */
    bindCornersToObject(polygon) {
      /**
       * @typedef {fabric.Control} Control
       * @property {number} pointIndex The index in {@link fabric.Polygon#points} that this control is for
       */

      /** @this Control */
      function polygonPositionHandler(dim, finalMatrix, fabricObject) {
        const x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x;
        const y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;

        return fabric.util.transformPoint(
            new fabric.Point(x, y),
            fabric.util.multiplyTransformMatrices(
                fabricObject.canvas.viewportTransform,
                fabricObject.calcTransformMatrix(), false
            )
        );
      }

      const actionHandler = (eventData, transform, x, y) => {
        const polygon = transform.target,
            currentControl = polygon.controls[polygon.__corner],
            mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
            polygonBaseSize = polygon._getNonTransformedDimensions(),
            size = polygon._getTransformedDimensions(0, 0);
        let newY = mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y;

        // Prevent movements outside canvas and outside original position if adjacent to channel
        if (polygon.position === 'left') {
          newY = Math.clamp(newY, this.catchmentMinY, this.leftAreaBottomY);
        } else {
          newY = Math.clamp(newY, this.rightAreaTopY, this.catchmentMaxY);
        }
        polygon.points[currentControl.pointIndex].y = newY;

        // Update measure (start/end width) in relevant input box
        const startWidth_px = Math.abs(polygon.points[3].y - polygon.points[0].y);
        const startWidth_m = this.px_to_m(startWidth_px)
        const endWidth_px = Math.abs(polygon.points[2].y - polygon.points[1].y);
        const endWidth_m = this.px_to_m(endWidth_px)

        // Update measures in store
        this.expectAreaChange = true;
        this.$store.commit('updateAreaMeasures', {
          position: polygon.position,
          area: this.selectedArea,
          startWidth: startWidth_m,
          endWidth: endWidth_m
        });

        return true;
      }

      function anchorWrapper(anchorIndex, fn) {
        return function (eventData, transform, x, y) {
          const fabricObject = transform.target,
              absolutePoint = fabric.util.transformPoint(new fabric.Point(
                  fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
                  fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y,
              ), fabricObject.calcTransformMatrix()),
              actionPerformed = fn(eventData, transform, x, y),
              polygonBaseSize = fabricObject._getNonTransformedDimensions(),
              newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
              newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
          fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
          return actionPerformed;
        }
      }

      const lastControl = polygon.points.length - 1;
      polygon.cornerStyle = 'circle';
      polygon.cornerColor = 'red';
      polygon.transparentCorners = false; //Fill in the circles
      polygon.controls = polygon.points.reduce((acc, point, index) => {
        if (
            // Prevent corners adjacent to channel to be editable (no round red circle)
            (point.y !== this.leftAreaBottomY && polygon.position === 'left') ||
            (point.y !== this.rightAreaTopY && polygon.position === 'right')
        ) {
          acc['p' + index] = new fabric.Control({
            positionHandler: polygonPositionHandler,
            actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
            mouseDownHandler: () => {
              polygon.lastScale = this.scale;
            },
            mouseUpHandler: () => {
              if (polygon.lastScale !== this.scale) {
                this.redrawBothSides(this.selectedArea);
              }
              delete polygon.lastScale;
            },
            actionName: 'modifyPolygon',
            pointIndex: index
          });
        }
        return acc;
      }, {});
    },
    handleDivide() {
      this.expectAreaChange = true;
      this.$store.commit('handleDivide', {
            position: this.selectedSide,
            area: this.selectedArea,
            leftSplit: this.divisionArea.leftSplit,
            rightSplit: this.divisionArea.rightSplit,
          }
      )
      this.isDividing = false;
      if (this.selectedSide === 'right') {
        this.redrawSide(this.selectedSide);
      } else {
        this.redrawBothSides();
      }
    },
    handleMerge() {
      const scale = this.scale, side = this.selectedSide;
      this.expectAreaChange = true;
      this.$store.commit('handleMerge', {
        position: side,
        areas: this.canvas.getActiveObjects().map(shape => shape.catchmentArea).sort((a, b) => a.xOrigin - b.xOrigin),
      });
      this.canvas.discardActiveObject(); //Avoid leaving the group selected when the underlying shapes are removed
      if (this.scale === scale && side === 'right') {
        this.redrawSide(side);
      } else {
        this.redrawBothSides();
      }
    },
    focusArea(area, {target}) {
      this.selectedSide = this.areas.leftAreas.includes(area) ? 'left' : 'right';
      this.selectedArea = area;
      this.canMerge = false;
      target.select();
      for (const shape of this.canvas.getObjects()) {
        if (shape.catchmentArea === area) {
          this.canvas.setActiveObject(shape);
          break;
        }
      }
    },
    onWidthChange(area, edge, {value}) {
      const scale = this.scale;
      this.expectAreaChange = true;
      this.$store.commit('updateAreaMeasures', {
        position: this.selectedSide,
        area: this.selectedArea,
        [edge]: value,
      });

      if (this.scale === scale) {
        this.redrawSide(this.selectedSide, this.selectedArea); //Shape has changed
      } else {
        this.redrawBothSides(this.selectedArea);
      }
    },
    onPermeabilityChange(area, permeability, value) {
      console.debug(`Change to ${this.selectedSide} area permeability`, permeability, value);

      this.$store.commit('updatePermeability', {
        position: this.selectedSide,
        area: this.selectedArea,
        permeability,
        permeabilityValue: value,
      });

      //Redraw the area given in case its fill colour needs to change
      this.redrawSide(this.selectedSide, this.selectedArea);
    },
    redrawSide(side, keepSelected = null) {
      const sideShapes = this.canvas.getObjects().filter(shape => shape?.position === side);
      this.canvas.remove(...sideShapes);

      if (side === 'left') {
        this.drawLeftAreas();
      } else {
        this.drawRightAreas();
      }

      if (keepSelected) {//When the side is redrawn any selected area will be replaced
        const selectedShape = this.canvas.getObjects().find(shape => shape.catchmentArea === keepSelected);

        if (!selectedShape) {//Setting the active object to undefined will crash
          if (sideShapes.some(shape => shape.catchmentArea === keepSelected)) {
            console.error("Selected area wasn't redrawn?", keepSelected);
          } else {
            console.error("Selected area isn't redrawn", keepSelected);
          }
        } else {
          this.canvas.setActiveObject(selectedShape);
        }
      }
    },
    redrawBothSides(keepSelected = null) {
      this.populateDrawing();

      if (keepSelected) {//When the sides are redrawn any selected area will be replaced
        const selectedShape = this.canvas.getObjects().find(shape => shape.catchmentArea === keepSelected);

        if (!selectedShape) {//Setting the active object to undefined will crash
          console.error("Selected area wasn't redrawn?", keepSelected);
        } else {
          this.canvas.setActiveObject(selectedShape);
        }
      }
    },
  },
  watch: {
    async channelLength_m(length) {
      const shouldDraw = await this.$store.dispatch('generateIrregularCatchment', {
        channelLength_m: length,
        catchmentArea_m2: this.catchmentArea_sqm,
      });
      if (shouldDraw) this.populateDrawing();
    },
    async catchmentArea_sqm(area) {
      //Don't regenerate the catchment shape if the catchment area change is expected
      if (this.expectAreaChange) return this.expectAreaChange = false;
      const shouldDraw = await this.$store.dispatch('generateIrregularCatchment', {
        channelLength_m: this.channelLength_m,
        catchmentArea_m2: area,
      });
      if (shouldDraw) this.populateDrawing();
    },
  }
}
</script>

<style scoped lang="scss">
label, span, ::v-deep(.p-inputtext), ::v-deep(td), td :not(.p-column-title) {
  font-size: 0.8rem !important;
}

::v-deep(.p-inputtext) {
  padding: 0 2px;
}

::v-deep(.p-component) {
  padding: 0
}

::v-deep(.p-inputnumber-input) {
  width: 70px;
  padding: 0 2px;
  text-align: right;
}

#canvas {
  /*border: 1px solid red*/
}

button {
  padding: 2px 4px !important;
  margin: 0.5rem 0;
  width: 75px;
}

#divide-merge-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.permeability-row {
  display: flex;
  align-items: center;

  .tooltip {
    margin-left: 0.5rem;

    ::v-deep(.tooltiptext) {
      bottom: 0.75rem;
      right: 0.5rem;
    }
  }
}

@media (max-width: 1900px) {
  #canvas-wrapper {
    display: flex;
    justify-content: center;
  }
}
</style>
