export default {
  "language": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemand"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
    "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongrois"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croate"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polonais"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovène"])}
  },
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau"])},
  "load": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier projet - Sélectionner un projet à charger"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager le projet"])},
    "shareText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour permettre l'accès à cette étude, copiez et collez le lien ci-dessous afin de le partager avec vos collègues ou vos clients :\n\n", _interpolate(_named("link")), "\n\nLes autres contacts devront s'enregistrer pour avoir accès à la même version du logiciel de conception hydraulique."])},
    "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le projet"])},
    "noProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de projet sauvegardé"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiche ", "{", "first", "}", " de ", "{", "last", "}", " à ", "{", "totalRecords", "}", " Projets"])},
    "progressBar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 de ", _interpolate(_named("total")), " taux de remplissage"]), _normalize([_interpolate(_named("count")), " de ", _interpolate(_named("total")), " taux de remplissage maximum"])])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le projet n'a pas pu être chargée"])}
  },
  "save": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder le projet"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager le projet avec Hauraton"])},
    "advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAURATON est en mesure de fournir des informations et des conseils lors de la vérification de la conception et / ou peut aider à obtenir les configurations de caniveaux supplémentaires sur demande."])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si une réponse urgente est requise, veuillez contacter Hauraton :"])},
    "calculateRuns": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les études suivantes doivent être calculées avant d'être sauvegardées : ", _interpolate(_named("runs"))])},
    "needProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le projet nécessite un nom"])},
    "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expirée, essayez de vous connecter avec un autre onglet/fenêtre, puis enregistrez à nouveau."])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'enregistrement du projet…"])}
  },
  "generic_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*La section transversale est générique et n'est utilisée qu'à titre d'illustration."])},
  "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du projet"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet"])},
  "projectDetailsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du projet"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "calculations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculs"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer"])},
  "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expirée, essayez de vous connecter avec un autre onglet/fenêtre et calculez à nouveau."])},
  "addRunLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau tronçon"])},
  "addRunLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un nouveau tronçon"])},
  "addNotesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarques"])},
  "addNotesLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des remarques sur le graphique du caniveau à dimensionner. Les remarques figureront dans les études hydrauliques."])},
  "resetLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
  "resetLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur peut rétablir les paramètres par défaut de l'exécution de l'étude en cours et de la fenêtre Paramètres de conception."])},
  "duplicateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer"])},
  "duplicateLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une copie du dimensionnement actuel"])},
  "catchmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bassin versant"])},
  "channelsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caniveaux"])},
  "resultsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats"])},
  "designRunsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebauche"])},
  "materialsListLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomenclature"])},
  "documentsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "selectionOfDrainageSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système de drainage"])},
  "selectionOfChannelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur intérieur de caniveau"])},
  "confirmedLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de la longueur du tronçon"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "freeOutflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecoulement libre"])},
  "outflowInTrashbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortie dans l'avaloir"])},
  "trashboxes": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection de l'avaloir"])},
    "chooseShared": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélection de l'avaloir ", _interpolate(_named("shared")), " commun"])},
    "withDischarge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avaloir avec ", _interpolate(_named("discharge")), "mm"])},
    "shareWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Partagé avec ", _interpolate(_named("run"))])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'avaloir disponible pour ce caniveau"])}
  },
  "modificationLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification de l'élément avec sortie"])},
  "noBuildInFall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans caniveau à pente intégrée"])},
  "definitionOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue caniveau avec perçage"])},
  "drillingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eléments avec sortie"])},
  "diameterOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diamètre de perçage"])},
  "diameterOfDrillingTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diamètre de perçage dans le caniveau"])},
  "distanceEdgeToDrillingCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance milieu perçage à fin du caniveau"])},
  "bottomAxial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milieu semelle"])},
  "leftSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paroi gauche "])},
  "rightSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paroi droite"])},
  "cuttingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elément avec coupe"])},
  "lengthFromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance de la fin du caniveau"])},
  "angleOfCutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angle de coupe"])},
  "designParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre de conception"])},
  "channelRunLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position "])},
  "channelRunLocationTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position caniveau/avaloir"])},
  "channelLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur du tronçon"])},
  "channelLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La longueur du tronçon de caniveau doit être un multiple de 1 mètre. Cette longueur ne tient pas compte de l'avaloir."])},
  "catchmentDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profondeur de terrain"])},
  "catchmentDepthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profondeur de terrain de chaque côté du tronçon."])},
  "catchmentArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bassin versant"])},
  "catchmentAreaTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface totale (m²) à drainer. Pour un bassin versant rectangulaire."])},
  "catchment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bassin versant variable"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de revêtement "])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Homogène\" - Bassin versant rectangulaire qui présente partout le même coefficient de perméabilité.\n\"Variable\" - Bassin versant qui peut être composé de plusieurs profondeurs de terraina et éventuellement de coefficients de perméabilité différents.\nVoir \"Calcul des bassins versants variables dans le fichier d'AIDE\"."])},
    "irregular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])},
    "uniform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homogène "])},
    "editShape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer le bassin versant "])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir"])}
  },
  "catchmentWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bassin versant maximal dépassé"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le bassin versant doit être inférieure à 100.000m², veuillez réduire votre surface d'au moins ", _interpolate(_named("excess")), "m²."])}
  },
  "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "configurationTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pente en cascade - Tronçon composé de caniveau de différents types de profondeurs, dans le sens de l'écoulement\n\"Type identique\" - Tronçon composée de types de caniveaux uniformes ayant la même profondeur (et largeur). \nPour plus d'informations, consultez le fichier d'aide"])},
  "stepped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pente en cascade"])},
  "stepWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarque"])},
    "concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les caniveaux ne peuvent pas être posé en cascade"])},
    "steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pente en cascade pas possible avec des caniveau en acier"])}
  },
  "rainfall": {
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte des pluviométries"])},
    "mapTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur l'icône \"goutte\" pour accéder à la carte des pluviométrie au Royaume-Uni. Sélectionnez des données pluviométriques précises pour des lieux spécifiques en cliquant à nouveau sur la carte des précipitations (Royaume-Uni). Les valeurs pluviométries peuvent également être saisies manuellement."])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
    "intensity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pluviométrie"])},
    "intensityTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer \"mm/h\" pour permuter vers \"l/sec/ha\" ou \"l/s/m²\""])},
    "stormDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de la pluviométrie"])},
    "r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r5"])},
    "r15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r15"])},
    "r60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r60"])},
    "default": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser la pluviométrie"])},
      "changeDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez vous définir la pluviométrie actuelle par défaut ?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])}
    }
  },
  "calculationMethod": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de calcul"])},
    "gvf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manning-Strickler "])},
    "hrw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HRW"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le logiciel de dimensionnement hydraulique HAURATON offre à l'utilisateur la possibilité de choisir entre deux modèles de calcul pour dimensionner les largeurs intérieures des caniveaux :\nGauckler-Manning-Strickler modifié (GMS).\nHydraulics Research Wallingford (HRW).\nPour plus d'informations, consultez le fichier 'Aide'."])}
  },
  "slopeSetting": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indication de la pente"])},
    "useSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pente"])},
    "useElevation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altimétrie"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pente\" - pente longitudinale du tronçon de caniveau exprimée en pourcentage.\nAltitude \" - pente de le sens d'écoulement du caniveau, déterminée à l'aide de la différence des cotes altimétriques au début et à la fin du tronçon de caniveau."])}
  },
  "longitudinalSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pente longitudinale"])},
  "longitudinalSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La pente longitudinale est la pente du caniveau dans le sens d'écoulement , et non la pente des bassins versants vers le caniveau. En cas de pose à plat, il convient d'indiquer 0%."])},
  "longitudinalSlopeWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pente d'écoulement maximale dépassé"])},
    "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La pente maximale autorisée, pour laquelle le modèle de dimensionnement hydraulique s'applique, est de 1:", _interpolate(_named("limit"))])}
  },
  "elevationChange": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisie de l'altimétrie"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les altimétries de début et de fin du tronçon de caniveau peuvent être utilisées pour le calcul hydraulique."])},
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partie supérieure"])},
    "discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortie"])}
  },
  "elevationWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport maximal de changement de hauteur dépassé"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La pente maximale autorisée, pour laquelle le modèle de dimensionnement hydraulique s'applique, est de 1:30."])}
  },
  "elevationUphillWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La capacité hydraulique du caniveau est inférieure au débit"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS3.0 a été conçu pour des systèmes de drainage linéaire ayant un débit variable, installés sur un sol plat ou avec une légère pente longitudinale. Veuillez vous assurer que le changement d'altitude ne génère pas une pente négative."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervertir les altitudes"])}
  },
  "superCriticalWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations importantes"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS3.0 a été conçu pour des systèmes de drainage linéaire ayant un débit variable, installés sur un sol plat ou avec une légère pente longitudinale."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le cas de caniveaux installés le long de fortes pentes (talus), le concepteur doit tenir compte du fait que le flux d'eau à l'intérieur du caniveau peut varier. Cela peut entraîner des turbulences dans le caniveau et générer d'éventuelles inondations locales."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les projets avec des fortes pentes, DS3.0 propose des dimensionnements avec des capacités hydrauliques des caniveaux suffisantes pour absorber le débit, mais ne tient pas compte des perturbations que cela génère dans les caniveaux, ni les conséquences qui en résultent.\n\n"])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour ces situations particulières, nous conseillons aux planificateurs de projets d'utiliser la méthodologie et la formule appropriées pour anticiper, prédire et prendre en compte les conditions de débit critique."])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne plus afficher"])}
  },
  "controlledDischarge": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduction de débit"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduction de débit"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduction de débit [l/s] ou \"0\" si sortie libre"])},
    "stepWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les calculs avec des réductions de débit ne peuvent pas être effectués avec des pentes intégrées ou en cascade."])},
    "free": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortie libre"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débit en écoulement libre basé sur l'événement pluvieux et le bassin versant."])}
    },
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
    "resetTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser sur \"sortie libre\""])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer"])},
    "calculateTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer le résultat de la limite"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
    "confirmTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser le débit de fuite pour la tronçon actuel"])},
    "flooded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun dimensionnement possible avec les paramètres actuels."])},
    "selectedChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caniveau sélectionné"])},
    "criticalDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée critique"])},
    "orificeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diamètre de sortie"])},
    "criticalRainfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pluviométrie critique"])},
    "totalChannelVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume total du caniveau"])},
    "minimumFreeBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "minimumChannelSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur minimum du caniveau"])},
  "minimumChannelSizeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur intérieur minimum du caniveau pour le dimensionnement en cours"])},
  "lengthWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur maximal recommandée dépassée"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La longueur maximale recommandée du caniveau est de 500 m. Veuillez réduire la longueur d'au moins ", _interpolate(_named("excess")), "m."])}
  },
  "lengthError": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur maximal du caniveau dépassée"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La longueur maximale du tronçon de caniveau ", _interpolate(_named("method")), " est ", _interpolate(_named("limit")), "m, veuillez réduire d'au moins ", _interpolate(_named("excess")), "m."])}
  },
  "divide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diviser"])},
  "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusionner"])},
  "divide&mergeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour plus d'informations sur la division et la fusion des bassins versants, voir le fichier AIDE (Dimensionnement pour des bassins versants non rectangulaires)."])},
  "complexCatchment": {
    "areaNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de bassin versant"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur"])},
    "startDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profondeur début TR "])},
    "endDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profondeur fin TR"])},
    "surfaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface"])},
    "permeability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perméabilité"])}
  },
  "areaLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur du bassin versant"])},
  "leftSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bassin versant gauche"])},
  "rightSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bassin versant droite"])},
  "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique"])},
  "designSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données du dimensionnement "])},
  "selectedSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système sélectionné"])},
  "channelNominalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur nominale caniveau"])},
  "channelInternalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur intérieure caniveau"])},
  "channelInternalDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profondeur intérieure caniveau"])},
  "channelRoughness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rugosité"])},
  "channelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de caniveau"])},
  "invertDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profondeur du fil d'eau"])},
  "overallDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur totale"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe de charge"])},
  "flowVelocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse d'écoulement à la fin du tronçon "])},
  "channelDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débit"])},
  "floodingRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux approximatif de surcharge"])},
  "dischargeFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débit de fuite"])},
  "usedVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume utilisé"])},
  "totalSystemVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume total du système"])},
  "freeboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bord libre"])},
  "installedGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pente"])},
  "installedGradientTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si les caniveaux sont posés en cascade, la différence de profondeur entre les types de caniveaux constituera la \"pente\" du tronçon de caniveaux. Dans ce cas, la pente résultante sera supérieure à la \"pente longitudinale\"."])},
  "hydraulicProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil hydraulique"])},
  "drainageArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bassin versant"])},
  "systemSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix de la gamme de caniveaux"])},
  "switchToAdvanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer à la version avancée"])},
  "switchToStandard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer à la version standard"])},
  "selectASystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une gamme de caniveau"])},
  "selectAFill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coefficient de perméabilité"])},
  "selectAFillTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coefficient de perméabilité (coefficient de ruissellement) - Cette valeur est liée à la nature du revêtement qui détermine le volume d'eau qui s'infiltre dans le caniveau après s'être écoulé à la surface du bassin versant."])},
  "permeability": {
    "concretePavement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Béton"])},
    "resinSurface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résine"])},
    "bpCement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavés avec joints imperméables"])},
    "asphalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrobé"])},
    "bpSand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavés avec joints perméables"])},
    "gravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recyclé"])},
    "softLandscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone engazonnée"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisé"])}
  },
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de projet"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société "])},
  "designer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "materials": {
    "han": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'article"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description produit"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "documents": {
    "exportLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter langue"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu projet"])},
    "downloadIntroduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger aperçu projet"])},
    "hydraulicAnalysis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dimensionnement hydraulique pour ", _interpolate(_named("run"))])},
    "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomenclature"])},
    "downloadMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger nomenclature"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
    "downloadInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger informations"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les documents"])},
    "downloadAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger tous les documents"])}
  },
  "endCapWithoutOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obturateurs avec sortie"])},
  "endcaps": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner l'obturateur"])},
    "withOutlet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("material")), " ", _interpolate(_named("diameter")), "mm sortie"])},
    "none": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pas d'obturateur disponible pour ", _interpolate(_named("channel"))])}
  },
  "switchSide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de page"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
  "pipeLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur du tuyau"])},
  "pipeLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur du tuyau sortant de la boîte d'évacuation / de sortie ponctuelle"])},
  "pipeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diamètre du tuyau"])},
  "pipeDiameterTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le diamètre du tuyau sortant de la boîte de vidange / d'évacuation ponctuelle"])},
  "pipeSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pente du tuyau"])},
  "pipeSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'angle du tuyau sortant de l'égout par rapport au sol."])},
  "extensionHat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rehausse"])},
  "extensionHatTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une rehausse peut être ajouter en cliquant sur le bouton  "])},
  "arrowsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glisser/déposer (clic gauche) le cercle rouge pour ajouter une arrivée d'eau à la canalisation.\nGlisser/déposer la flèche bleue pour ajouter un accessoire.\nCliquer avec le bouton droit de la souris sur le cercle rouge / la flèche bleue pour les supprimer du tronçon."])},
  "pointInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrivée d'eau\nsupplémentaire"])},
  "accessory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessoires"])},
  "distanceFromDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance jusqu'à la sortie"])},
  "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profondeur"])},
  "grating": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grille"])},
    "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la grille"])},
    "slot_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur et longueur de la fente"])},
    "open_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur de la grille"])}
  },
  "positionWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y avait au moins une arrivée d'eau supplémentaire ou un accessoire positionné au-delà de la longueur du tronçon, ils ont donc tous été supprimés. Veuillez les rajouter si nécessaire."])}
  },
  "accessorySwitchLoss": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les accessoires ont été supprimés pour permettre le passage au mode avancé."])}
  },
  "popups": {
    "inflowDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'arrivée d'eau"])},
    "accessoryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails accessoires"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
    "directPipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuyau"])},
    "flowRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débit"])},
    "connectionMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de connexion"])},
    "independent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indépendant"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "trashBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaloir"])},
    "accessBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regard"])},
    "accessCover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trappe de visite"])},
    "endEap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obturateur"])},
    "endEapWithOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obturateur avec manchon"])},
    "concreteChamber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regard en béton"])},
    "noAccessoriesAvailable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissement"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'accessoires compatibles."])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez nous contacter pour plus d'informations."])}
    },
    "distanceTooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance vers sortie trop grande"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "webLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien "])},
  "staffAdmin": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur-Administrateur"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précèdent"])},
    "fact": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Actualité : ", _interpolate(_named("fact"))])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "isBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquer"])},
    "hasAdvancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode avancé"])},
    "isStaff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer premier à dernier de total utilisateur"])}
  },
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnecter"])},
  "waterInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrivée d'eau supplémentaire"])},
  "waterInflowTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonction permet d'ajouter une arrivée d'eau supplémentaire. Ajouter le débit en litres / seconde (l/s)."])},
  "selectChannelFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un caniveau "])},
  "lengthDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le caniveau sélectionné n'est pas compatible avec la longueur indiquée"])},
  "userSettings": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages personnalisés"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "defaultMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode standard"])},
    "standardMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "advancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancé"])},
    "userRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unité de la pluviométrie par défaut"])},
    "projectRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unité de la pluviométrie du projet"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà un compte"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter"])},
    "forgottenPassword": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mot de passe oublié? Clic ", _interpolate(_named("here"))])},
    "doNotHaveAccount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si vous n'avez pas encore de compte, inscrivez-vous ", _interpolate(_named("here")), "."])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])}
  },
  "passwordReset": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser mot de passe"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour réinitialiser le mot de passe, saisissez votre adresse électronique ci-dessous."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser mot de passe"])},
    "otherwiseLogIn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous pouvez vous connecter ", _interpolate(_named("here")), "."])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe :"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer mot de passe :"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer mot de passe"])},
    "invalidLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien invalide"])},
    "invalidLinkTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation mot de passe, si nécessaire."])}
  },
  "softwareFeatures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations logiciel"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version anglaise"])},
    "asia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version asiatique"])},
    "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version européenne/international"])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour d'expérience"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partagez votre expérience"])},
    "europe&beyond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europe et monde"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Royaume-Uni"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacter nous pour de plus ample informations"])}
  },
  "freedomAdvertBox": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage gratuit"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre nouvelle application en ligne \"DesignSoftware\" permet aux professionnels d'effectuer rapidement et simplement des dimensionnements hydrauliques, de concevoir des projets et de spécifier des produits de drainage de surface Hauraton. Ceci est possible depuis votre propre ordinateur, ou tablette."])},
    "justThreeClicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensionnement en trois clics"])}
  },
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "createAnAccountParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous utilisez ce site pour la première fois, veuillez créer un compte ci-dessous :"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "registrationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement réussi"])},
  "checkConfirmationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un lien de confirmation vous a été envoyé par mail"])},
  "designReassurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiabilité du logiciel de dimensionnement"])},
  "reassuranceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DesignSoftware a été testé de manière approfondie et sa méthodologie a été validée par une société indépendante de conseil en hydraulique. Notre logiciel de conception hydraulique \"maison\" est utilisé avec succès par le personnel technique d'Hauraton depuis plus de 30 ans, avec une fiabilité totale."])},
  "confidential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentiel"])},
  "confidentialParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les utilisateurs enregistrés (protégés par un mot de passe) ont accès à un serveur de stockage sécurisé pour sauvegarder leur dimensionnements. Les projets restent strictement confidentiels, sauf si les utilisateurs cochent la case \"partager avec Hauraton\". Les utilisateurs enregistrés ont la possibilité de partager leurs dimensionnements avec leurs collègues ou clients enregistrés. DesignSoftware a été configuré pour se conformer pleinement à la réglementation RGPD sur la \"confidentialité des données\"."])},
  "hydraulicDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle de calcul hydraulique - Modèles supplémentaires"])},
  "hydraulicDesignParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour le dimensionnement hydraulique d'autres systèmes non inclus dans notre DesignSoftware, veuillez vous adresser à votre filiale Hauraton ou à votre partenaire commercial local pour bénéficier d'une assistance technique."])},
  "experienceAndExpertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expérience et expertise"])},
  "experienceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader mondial dans la fabrication de systèmes de drainage de surface, les produits Hauraton sont utilisés dans des projets majeurs sur les marchés internationaux depuis plus de soixante-cinq ans. Nous avons développé notre premier système de drainage linéaire en 1956. Depuis, la marque Hauraton est connue dans le monde entier comme une référence en matière de qualité, de fiabilité, de durabilité et de service."])},
  "help": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])},
    "topicsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "videoTutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidéo - Tutoriels"])},
    "topic": {
      "about_hauraton_designsoftware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A propos du programme de dimensionnement Hauraton"])},
      "design_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de calcul"])},
      "menu_bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctions de la barre de menu"])},
      "managing_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des projets"])},
      "project_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations du projet"])},
      "design_functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctions de paramétrage du dimensionnement "])},
      "design_parameters_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fenêtre de paramétrage du dimensionnement"])},
      "irregular_catchment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définition bassin versant non rectangulaire"])},
      "rainfall_map_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pluviométrie (version UK)"])},
      "point_inflows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrivée d'eau supplémentaire"])},
      "adding_accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un accessoire"])},
      "access_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'une révision"])},
      "advanced_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passé à avancé"])},
      "design_specification_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fenêtre de paramétrage du système"])},
      "system_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information sur la famille de produit"])},
      "recyfix_hicap_slot_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECYFIX HICAP Caniveau à fente"])},
      "system_installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation du système"])},
      "design_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide à la conception"])},
      "allow_flood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surcharge autorisée"])}
    }
  }
}