export default {
  "language": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch"])},
    "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungarisch"])},
    "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italienische"])},
    "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroatische"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polnisch"])},
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slowenisch"])}
  },
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
  "load": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Projekt aus"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektnummer"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersteller"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt teilen"])},
    "shareText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um Zugang zu diesem Projektentwurf zu gewähren, kopieren Sie den unten stehenden Link und fügen Sie ihn ein, um ihn mit Kollegen oder Kunden zu teilen:\n\n", _interpolate(_named("link")), "\n\nAndere Parteien müssen sich für den Zugang zu derselben Version der Hydraulikentwurfssoftware registrieren."])},
    "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfung kopieren"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt löschen"])},
    "noProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine gespeicherten Projekte"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigt ", "{", "first", "}", " bis ", "{", "last", "}", " von ", "{", "totalRecords", "}", " Projekte"])},
    "progressBar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 von ", _interpolate(_named("total")), " Rinnenstränge geladen"]), _normalize([_interpolate(_named("count")), " von ", _interpolate(_named("total")), " Rinnensträngen geladen"])])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt konnte nicht geladen werden"])}
  },
  "save": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "as-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als neues Projekt speichern"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt speichern"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt mit HAURATON teilen"])},
    "advice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAURATON kann bei der Überprüfung des Entwurfs wertvolle Anregungen und Ratschläge geben und/oder durch Value Engineering die kostengünstigsten Kanalkonfigurationen ermitteln."])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn nach der Teilen eines Projekts eine dringende Antwort erforderlich ist, kontaktieren Sie bitte HAURATON:"])},
    "calculateRuns": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die folgenden Läufe müssen vor dem Speichern berechnet werden: ", _interpolate(_named("runs"))])},
    "needProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Projekt benötigt einen Namen"])},
    "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung abgelaufen, versuchen Sie, sich mit einer anderen Registerkarte/Fenster anzumelden und dann erneut zu speichern"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt konnte nicht gespeichert werden..."])}
  },
  "generic_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Der Rinnenquerschnitt ist beispielhaft und dient der Veranschaulichung"])},
  "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektname"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
  "projectDetailsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektdetails"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "calculations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnungen"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnen"])},
  "expiredSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung abgelaufen, versuchen Sie, sich mit einer anderen Registerkarte/einem anderen Fenster anzumelden und dann erneut zu berechnen"])},
  "addRunLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Strang"])},
  "addRunLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen weiteren Rinnenstrang hinzufügen"])},
  "addNotesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkungen"])},
  "addNotesLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkungen zum Rinnenstrang hinzufügen. Bemerkungen werden in die Dokumente zur hydraulischen Berechnung aufgenommen."])},
  "resetLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "resetLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Nutzer kann die Daten und die Designparameter des markierten Rinnenstrangs auf die Grundeinstellungen zurücketzen."])},
  "duplicateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strang kopieren"])},
  "duplicateLabelTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf des aktuellen Rinnenstrangs kopieren."])},
  "catchmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzugsgebiet"])},
  "channelsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinnenstränge"])},
  "resultsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse"])},
  "designRunsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
  "materialsListLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stückliste"])},
  "documentsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente"])},
  "selectionOfDrainageSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinnensystem"])},
  "selectionOfChannelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nennweite"])},
  "confirmedLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolle Stranglänge"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhe"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stück"])},
  "freeOutflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freier Auslauf"])},
  "outflowInTrashbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablauf in Einlaufkasten"])},
  "trashboxes": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl Einlaufkasten"])},
    "chooseShared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl gemeinsamer Einlaufkasten"])},
    "withDischarge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Einlaufkasten mit ", _interpolate(_named("discharge")), "mm Auslauf"])},
    "shareWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gemeinsam mit ", _interpolate(_named("run"))])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Einlaufkasten für dieses Rinnensystem verfügbar"])}
  },
  "endCapWithoutOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stirnwand mit Ablaufstutzen"])},
  "endcaps": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stirnwand auswählen"])},
    "withOutlet": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("material")), " ", _interpolate(_named("diameter")), "mm Ablaufstutzen"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine Stirnwand verfügbar für dieses Rinnensystem"])}
  },
  "modificationLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitung Rinnenelement mit Ablauf"])},
  "noBuildInFall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Rinnen mit Eigengefälle"])},
  "headTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinnenanfang"])},
  "editHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten Stranganfang"])},
  "editingHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitung Stranganfang"])},
  "dischargeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auslauf"])},
  "editDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten Strangauslauf"])},
  "editingDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitung Strangauslauf"])},
  "drillingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element mit Ablaufbohrung"])},
  "diameterOfDrilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchmesser der Bohrung"])},
  "diameterOfDrillingTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchmesser des in die Rinne zu bohrenden Lochs"])},
  "distanceEdgeToDrillingCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand: Ende der Rinne bis Mitte Bohrung"])},
  "bottomAxial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sohle Mittig"])},
  "leftSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linke Rinnenwand"])},
  "rightSideWall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechte Rinnenwand"])},
  "cuttingAtFirstElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuschnitt am Stranganfang"])},
  "cuttingAtLastElement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuschnitt am Strangende"])},
  "lengthFromBeginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand vom Rinnenende"])},
  "angleOfCutting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuschnittswinkel"])},
  "designParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurfsparameter"])},
  "channelRunLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einbauposition"])},
  "channelRunLocationTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einbauposition Rinnenstrang / Punktentwässerung"])},
  "channelLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge Rinnenstrang"])},
  "channelLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Länge des Rinnenstrangs sollte ein Vielfaches von 1 Meter sein. Diese Länge beinhaltet nicht die Länge eines Einlaufkastens"])},
  "catchmentDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiefe des Einzugsgebiets"])},
  "catchmentDepthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernung des Einzugsgebiets von jeder Längsseite des Kanals."])},
  "catchmentArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzugsgebiet"])},
  "catchmentAreaTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtfläche (m²), die mit dem Rinnenstrang entwässert werden soll. Es wird davon ausgegangen, dass das Einzugsgebiet rechteckig ist"])},
  "catchment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzugsgebiet variabel"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flächentyp"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Rechteckige Einzugsgebiete rechts und links der Rinne\":\nTeilgebiete sind symmetrisch entland dem Rinnenstrang.\n\"Benutzerdefiniert\":\nEinzugsgebiet bestehend aus ein oder mehreren Teilgebieten, die unterschidliche Dimensionen und Öberflächen aufweisen können und nicht symetrisch zur Rinnenachse sein müssen.\nWeiteres siehe Hilfe-Datei"])},
    "irregular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefiniert"])},
    "uniform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechteckiges Einzugsgebiet rechts und links der Rinne"])},
    "editShape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form des Einzugsgebiets bearbeiten"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen"])}
  },
  "catchmentWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Größe des Einzugsgebietes überschritten"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Einzugsgebiet muss kleiner als 100.000m² sein, bitte reduzieren Sie Ihren Entwurf um mindestens ", _interpolate(_named("excess")), "m²"])}
  },
  "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration"])},
  "configurationTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufengefälle' - Rinnenstrang aus Rinnentypen mit unterschiedlichen Sohlentiefen, Abstufung in Fließrichtung\n'Typengleich' - Rinnenstrangs aus einheitlichen Rinnentypen gleicher Sohlentiefe (und Breite) \nWeitere Informationen finden Sie in der Hilfe-Datei"])},
  "stepped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufengefälle "])},
  "stepWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis"])},
    "concrete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die ausgewählte Konfiguration ist ein Stufengefälle nicht möglich."])},
    "steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufengefälle bei metallischen Rinnen nicht möglich"])}
  },
  "rainfall": {
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte der Regenereignisse"])},
    "mapTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf das Regentropfensymbol um auf die Karte mit den Niederschlägen für Großbritannien zu gelangen. Wählen Sie Regenspenden für bestimmte Orte aus, indem Sie auf die entsprechenden Orte klicken. Die Regenspenden können auch manuell eingegeben werden."])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "intensity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regenspende"])},
    "intensityTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf die Einheit 'mm/h' um zu 'l/s*ha' oder 'l/sec/m²' zu wechseln"])},
    "stormDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer des Regenereignises"])},
    "r5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r5"])},
    "r15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r15"])},
    "r60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["r60"])},
    "default": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurücksetzen der Regenereignisdaten"])},
      "changeDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soll das gewählte Regenereignis als Standard definieren werden?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])}
    }
  },
  "calculationMethod": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnungsmodell"])},
    "gvf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMS"])},
    "hrw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HRW"])},
    "tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["HAURATON DesignSoftware bietet dem Anwender die Möglichkeit, zur Berechnung von Rinnennennweiten zwischen zwei hydraulischen Bemessungsformeln (Berechnungsmethoden) zu wählen:", _interpolate(_list(0)), "Formel nach Gauckler-Manning-Strickler (GMS)", _interpolate(_list(0)), "Hydraulics Research Wallingford (HRW).", _interpolate(_list(0)), "Weitere Informationen finden Sie in der 'Hilfe'-Datei."])}
  },
  "slopeSetting": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefälleangabe"])},
    "useSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefälle"])},
    "useElevation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenkoten"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Gefälle' - Längsgefälle des Rinnenstrangs als prozentualer Wert\n'Höhenkote' - Gefälle des Rinnenstrans, ermittelt anhand unterschiedlicher Höhenkoten am Anfang und am Ende des Rinnenstrangs."])}
  },
  "longitudinalSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Längsgefälle"])},
  "longitudinalSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Längsgefälle ist das Gefälle der Strangverlaufs, nicht das auf den Rinnenstrang zulaufende Gefälle der angeschlossenen Flächen. Bei waagerechtem Einbau sind 0% anzugeben."])},
  "longitudinalSlopeWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaler Längsgefälle überschritten"])},
    "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das maximal zulässige Gefälle, für das die hydraulische Theorie gilt, beträgt 1:", _interpolate(_named("limit"))])}
  },
  "elevationChange": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe Höhenkoten"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Höhenkoten der Oberfläche am Start und am Ende des Rinnenstrangs können für die hydraulische Berechnung herangezogen werden."])},
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stranganfang"])},
    "discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strangeinde (Auslauf)"])}
  },
  "elevationWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximales Höhenänderungsverhältnis überschritten"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Das maximal zulässige Gefälle, für das die hydraulische Theorie gilt, beträgt 1:30.", _interpolate(_list(0)), "Verringern Sie bitte den Höhenunterschied Ihres Entwurfs oder verlängern Sie ihn"])}
  },
  "elevationUphillWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Strang weiost ein Kontergefälle auf!"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["DS3.0 wurde für Rinnenstränge  entwickelt, die waagerecht oder mit leichtem positivem Längsgefälle verlegt werden.", _interpolate(_list(0)), "Bitte stellen Sie sicher, dass der Höhenunterschied nicht zu einem negativen Gefälle führt."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenkoordinaten wechseln"])}
  },
  "superCriticalWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtige Informationen"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS3.0 wurde unter der Annahme eines allmählich steigenden Durchflusses in linearen Entwässerungssystemen entwickelt, die waagerecht oder mit leichtem Längsgefälle verlegt sind."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Rinnenverläufen, entlang steilerer Hänge ist zu berücksichtigen, dass der Durchfluss innerhalb des Rinnensystems zwischen unterkritischen und überkritischen Zuständen schwanken kann. Dies kann zu größeren Turbulenzen und möglichen lokalen Überlastungen im Gerinne führen."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Projekte mit steileren Längsgefällen bietet DS3.0 Gerinneentwürfe mit ausreichender Kapazität, um den berechneten Durchfluss aufzunehmen, berücksichtigt aber nicht die schnell variierende Strömung, die innerhalb des Gerinnekörpers mit den daraus resultierenden Folgen auftreten kann."])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für solche speziellen Situationen raten wir den Projektplanern, die entsprechende Methodik und Formel zu verwenden, um überkritische Durchflussbedingungen zu antizipieren, vorherzusagen und zu berücksichtigen."])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht mehr anzeigen"])}
  },
  "controlledDischarge": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drosselabfluss"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drosselabfluss"])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drosselabfluss  [l/s] oder \"0\" wenn freier Auslauf"])},
    "stepWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnungen mit Drosselabfluss können nicht mit Längs- oder Stufengefälle ausgeführt werden."])},
    "free": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freier Auslauf"])},
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abfluss bei freiem Auslauf basierend auf dem Regenereignis und der Einzugsfläche."])}
    },
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
    "resetTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf 'Freien Auslauf' zurücksetzen"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnen"])},
    "calculateTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnen der Grenzwerte"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übernehmen"])},
    "confirmTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drosselabfluss für aktuellen Strang benutzen"])},
    "flooded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit den gegebenen Parameteren kann kein Rinnensystem ausgewählt werden."])},
    "selectedChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewählte Rinne"])},
    "criticalDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritische Dauer des Regenereignis"])},
    "orificeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchmesser der Auslassöffnung"])},
    "criticalRainfall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kritische Niederschlagsmenge"])},
    "totalChannelVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtes Volumen des Rinnenstrangs"])},
    "minimumFreeBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimales Freibord"])}
  },
  "minimumChannelSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minmale Nennweite der Rinne"])},
  "minimumChannelSizeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestnennweite des Rinensystem für den aktuellen Rinnenstrang anwenden."])},
  "lengthWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale empfohlene Länge des Rinnenstrangs überschritten"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die empfohlene Maximallänge eines Rinnenstrangs beträgt 500 m"])}
  },
  "lengthError": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Länge des Rinnenstrangs überschritten"])},
    "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Maximallänge eines ", _interpolate(_named("method")), " Rinnenstrangs beträgt ", _interpolate(_named("limit")), " m"])}
  },
  "divide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen"])},
  "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden"])},
  "divide&mergeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zur Aufteilung / zum Verbinden von Einzugsgebieten finden Sie in der HILFE-Datei (Berechnung von untegelmäßigen Einzugsgebieten)"])},
  "complexCatchment": {
    "areaNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzugsgebiets-Nr."])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge"])},
    "startDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiefe am Anfang"])},
    "endDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiefe am Ende"])},
    "surfaceArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche"])},
    "permeability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oberfläche"])}
  },
  "areaLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge Einzugsgebiet"])},
  "leftSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkes Einzugsgebiet"])},
  "rightSplit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtes Einzugsgebiet"])},
  "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typengleich"])},
  "designSpecification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydraulische Bemessung"])},
  "channelNominalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nennweite der Rinne"])},
  "channelInternalWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breite der Rinne innen"])},
  "channelInternalDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sohlentiefe der Rinne"])},
  "channelRoughness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rauheit"])},
  "selectedSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewähltes System"])},
  "channelType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinnentyp"])},
  "invertDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sohlentiefe"])},
  "overallDepth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamthöhe"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastklasse"])},
  "flowVelocity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fliessgeschwindigkeit am Ablauf"])},
  "channelDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchfluss"])},
  "floodingRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungefähre Auslastung"])},
  "dischargeFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abflussmenge"])},
  "maxDischargeFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximal möglicher Abfluss"])},
  "usedVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genutztes Rinnenvolumen"])},
  "totalSystemVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retentionsvolumen"])},
  "freeboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freibord"])},
  "installedGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultierendes Gefälle"])},
  "installedGradientTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn der Rinnenstrang als \"Stufengefälle\" konzipiert ist, tragen die Höhenunterschiede der verschiedenen Rinnentypen zum \"Resultierenden Gefälle\" bei. In solchen Fällen ist das \"Resultierende Gefälle\" größer als das \"Längsgefälle\"."])},
  "hydraulicProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydraulikprofil"])},
  "drainageArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwässerungsgebiet"])},
  "systemSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl Rinnensystem"])},
  "switchToAdvanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Expertenmodus"])},
  "switchToStandard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Standardmodus"])},
  "selectASystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Rinnensystem aus"])},
  "selectAFill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abflussbeiwert"])},
  "selectAFillTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abflussbeiwert – Dieser Wert definiert die Durchlässigkeit und Beschaffenheit der Einzugsgebietsoberfläche und bestimmt die Wassermenge, die das Entwässerungsystem erreicht, nachdem es das Einzugsgebiet überflossen hat."])},
  "permeability": {
    "concretePavement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betonoberfläche"])},
    "resinSurface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epoxidharzoberfläche"])},
    "bpCement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflastersteine mit Fugenmörtel"])},
    "asphalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asphalt"])},
    "bpSand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflastersteine mit Sand verfugt"])},
    "gravel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schotterbelag"])},
    "softLandscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rasenfläche"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefiniert"])}
  },
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
  "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektnummer"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
  "designer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiter"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "materials": {
    "han": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktbeschreibung"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])}
  },
  "documents": {
    "exportLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache exportieren"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektübersicht"])},
    "downloadIntroduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektübersicht laden"])},
    "hydraulicAnalysis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hydraulische Bemessung für ", _interpolate(_named("run"))])},
    "materials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stückliste"])},
    "downloadMaterials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stückliste herunterladen"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen"])},
    "downloadInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen herunterladen"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Dokumente"])},
    "downloadAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Dokumente herunterladen"])}
  },
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizen"])},
  "switchSide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite wechseln"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbruch"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annehmen"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "pipeLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rohrlänge"])},
  "pipeLengthTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Länge des an den Punktablauf/Einlaufkasten angeschlossenen Rohres"])},
  "pipeDiameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rohrdurchmesser"])},
  "pipeDiameterTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Durchmesser des an den Punktablauf/Einlaufkasten angeschlossenen Rohres"])},
  "pipeSlope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefälle der Rohrleitung"])},
  "pipeSlopeTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Winkel des an den Punktablauf/Einlaufkasten angeschlossenen Rohres ist relativ zur Oberfläche"])},
  "extensionHat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufsatzstück"])},
  "extensionHatTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufsatzstück zur Erhöhung des Punkteinlaufs mit Klick auf den Schalter hinzufügen"])},
  "arrowsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziehen Sie den roten Punkt (linke Maustaste), um dem Rinnenstrang einen Punktzulauf hinzuzufügen.\nZiehen Sie den blauen Pfeil (linke Maustaste), um dem Rinnenstrang Zubehör hinzuzufügen.\nKlicken Sie mit der rechten Maustaste auf den roten Punkt / blauen Pfeil, um ihn aus dem Rinnenstrang zu löschen."])},
  "pointInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktueller\nZulauf"])},
  "accessory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zubehör"])},
  "distanceFromDischarge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand zum Auslauf"])},
  "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiefe"])},
  "grating": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abdeckung"])},
    "pleaseSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl Abdeckung"])},
    "slot_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlitzbreite/-länge"])},
    "open_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einlaufquerschnitt"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge der Abdeckung"])}
  },
  "positionWarning": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens ein Zulauf oder Zubehörartikel wurde außerhalb der neu festgelegten Länge des Rinnenstrangs positioniert, daher wurden sie alle entfernt. Bitte fügen Sie sie diese, falls gewünscht, wieder hinzu."])}
  },
  "accessorySwitchLoss": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Zubehör wurde entfernt, um den Wechsel in den erweiterten Modus zu ermöglichen. Bitte fügen Sie es nach Wunsch wieder hinzu."])}
  },
  "popups": {
    "inflowDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zum Zulauf"])},
    "accessoryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zum Zubehör"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
    "directPipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direktes Rohr"])},
    "flowRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchfluss"])},
    "connectionMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art des anschlusses"])},
    "independent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unabhängig"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "trashBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einlaufkasten"])},
    "accessBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revision mit Einlaufkasten"])},
    "accessCover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisionselement"])},
    "endEap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stirnwand"])},
    "endEapWithOutlet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stirnwand mit Auslauf"])},
    "concreteChamber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betonschacht"])},
    "noAccessoriesAvailable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnung"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt kein Zubehör, das mit der gewählten Position kompatibel ist."])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte kontaktieren Sie HAURATON für weitere Informationen oder Hilfe."])}
    },
    "distanceTooBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand zum Auslauf zu groß"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "webLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weblinks"])},
  "sharedProjects": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geteilte Projekte"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Projekt aus"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektnummer"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt von"])},
    "saver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt gespeichert von"])},
    "noProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine geteilten Projekte"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigt ", "{", "first", "}", " bis ", "{", "last", "}", " von ", "{", "totalRecords", "}", " geteilte Projekten"])}
  },
  "staffAdmin": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter-Admin"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "fact": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fakt des Tages: ", _interpolate(_named("fact"))])},
    "channelAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinnenverfügbarkeit"])},
    "hideBlockedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sperrige Nutzer ausblenden"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständiger Name"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "allProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Projekte"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
    "isBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sperren"])},
    "deleteUser": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzer löschen"])},
      "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie den Nutzer \"", _interpolate(_named("username")), "\" (", _interpolate(_named("name")), ") löschen wollen?"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])}
    },
    "group": {
      "column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
      "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardnutzer"])},
      "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortgeschrittener Nutzer"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAURATON Support"])},
      "translator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzer"])},
      "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
      "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superadministrator"])}
    },
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigt ", "{", "first", "}", " bis ", "{", "last", "}", " von ", "{", "totalRecords", "}", " Benutzer"])},
    "userProjects": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Name"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektnummer"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Land"])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])},
      "lastSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt gespeichert"])},
      "saver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt gespeichert von"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum gelöscht"])},
      "pagination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigt ", "{", "first", "}", " bis ", "{", "last", "}", " von ", "{", "totalRecords", "}", " geteilte Projekten"])}
    }
  },
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Website verwendet nur wichtige Cookies"])},
  "waterInflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserzufluss"])},
  "waterInflowTT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Funktion bietet die Möglichkeit, einen Zulauf in den Punktablauf einzubinden. Fügen Sie das Volumen des Punktzuflusses in Liter/Sekunde (l/s) hinzu."])},
  "selectChannelFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie zuerst eine Rinne aus."])},
  "lengthDoNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Länge der ausgewählten Rinnen stimmen nicht mit der angegebenen Länge des Rinnenstrangs überein."])},
  "auto-cut": {
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Länge der ausgewählten Rinnen ist kürzer als die angegebene Länge des Stranges."])},
    "long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Länge der ausgewählten Rinnen ist länger als die angegebene Länge des Stranges."])},
    "corrected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies Deifferenz kann durch kürzen der Rinne an einem Ende automatisch korrigiert werden."])},
    "run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduzierte Stranglänge"])},
    "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kürzen am Stranganfang"])},
    "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelle Korrektur"])},
    "discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kürzen am Strangende"])}
  },
  "userSettings": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzereinstellungen"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
    "defaultMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardmodus"])},
    "standardMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "advancedMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweitert"])},
    "userRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte  Einheit der Niederschlagsintensität / des Regenereignisses"])},
    "projectRainfallUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektbezogene Einheit der Niederschlagsintensität / des Regenereignisses"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANMELDEN"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie bereits ein Konto auf dieser Website haben, melden Sie sich bitte hier an:"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzername"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "forgottenPassword": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Passwort vergessen? Klicken Sie ", _interpolate(_named("here")), " um es zurückzusetzen."])},
    "doNotHaveAccount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben noch kein Konto? Registrieren Sie sich ", _interpolate(_named("here")), "."])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])}
  },
  "passwordReset": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie unten Ihre E-Mail-Adresse ein und wir senden Ihnen an diese Adresse einen Link, über den Sie Ihr Passwort zurücksetzen können.\nSollten Sie keine E-Mail erhalten, ist Ihre E-Mail-Adresse noch nicht registriert."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort Zurücksetzen"])},
    "otherwiseLogIn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ansonsten können Sie sich von ", _interpolate(_named("here")), "."])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort:"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen:"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "invalidLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Link zum Zurücksetzen des Passworts war ungültig, möglicherweise weil er bereits verwendet wurde."])},
    "invalidLinkTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte fordern Sie eine neue Kennwortrücksetzung an, falls noch erforderlich."])}
  },
  "softwareFeatures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOFTWARE-FUNKTIONEN"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version UK"])},
    "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version Europa/International"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAURATON behält sich das recht vor, den Zugang zu diesem Programm zu verweigern oder zu sperren und Einsicht in die eventuell erstellten Projekte und den Berechnungsverlauf zu nehmen."])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie uns Feedback zu unserer DesignSoftware geben möchten, beachten Sie bitte die folgenden Hinweise:"])},
    "europe&beyond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europa & Welt"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinigtes Königreich"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns für weitergehende Designunterstützung"])}
  },
  "freedomAdvertBox": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestaltungsfreiheit, kostenlose Nutzung"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere neue webbasierte Anwendung \"DesignSoftware\" bietet Fachleuten aus der Branche eine schnelle und einfache hydraulische Bemessung von Rinnen aus der HAURATON Produktpalette und ermöglicht die Projektplanung vom eigenen Rechner aus."])},
    "justThreeClicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lediglich drei Klicks für eine Berechnung"])}
  },
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung"])},
  "createAnAccountParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollten Sie diese Website zum ersten Mal benutzen, erstellen Sie bitte unten ein Konto:"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort-Bestätigung"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
  "registrationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung erfolgreich gespeichert"])},
  "checkConfirmationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte prüfen Sie Ihre E-Mail auf einen Bestätigungslink"])},
  "designReassurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuverlässigkeit der DesignSoftware"])},
  "reassuranceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die DesignSoftware wurde ausgiebig getestet und die Methodik von einem unabhängigen Hydraulikberatungsunternehmen validiert. Unsere \"hauseigene\" Hydrauliksoftware wird von HAURATON-Technikern seit über 30 Jahren erfolgreich und absolut zuverlässig eingesetzt."])},
  "confidential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertraulich"])},
  "confidentialParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierte Nutzer haben Zugang zu einem sicheren Serverspeicher für ihre Entwürfe. Die Entwürfe bleiben streng vertraulich. Registrierte Benutzer haben die Möglichkeit, Designdateien zu exportieren, um sie auf ihren eigenen Computern zu speichern. \nDie DesignSoftware wurde so konfiguriert, dass sie die GDPR-Datenschutzbestimmungen erfüllt."])},
  "hydraulicDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hydraulisches Design - weitere Systeme"])},
  "hydraulicDesignParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die hydraulische Bemessung weiterer HAURATON-Systeme, die nicht in unserer DesignSoftware enthalten sind, wenden Sie sich bitte an Ihre HAURATON-Niederlassung oder Ihren Handelspartner vor Ort, um technische Unterstützung zu erhalten."])},
  "experienceAndExpertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahrung und Kompetenz"])},
  "experienceParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAURATON ist einer der weltweit führenden Hersteller modularer Oberflächenentwässerungssysteme. HAURATON Entwässerungssysteme finden seit mehr als 65 Jahre bei Großprojekten in internationalen Märkten Verwendung. 1956 entwickelten wir unser erstes lineares Entwässerungssystem. Seitdem ist die Marke HAURATON weltweit als Maßstab für Qualität, Zuverlässigkeit, Langlebigkeit und Service bekannt."])},
  "help": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])},
    "topicsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfethemen"])},
    "videoTutorials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video-Tutorials"])},
    "topic": {
      "about_hauraton_designsoftware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über die HAURATON DesignSoftware"])},
      "design_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnungsmethode"])},
      "menu_bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionen der Menüleiste"])},
      "managing_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten von Projekten"])},
      "project_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektinformationen"])},
      "design_functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designfunktionen"])},
      "design_parameters_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fenster Designparameter"])},
      "irregular_catchment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe variabler Einzugsgebiete"])},
      "rainfall_map_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederschlagskarte und Daten (UK Version)"])},
      "point_inflows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktzuläufe"])},
      "adding_accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zubehör hinzufügen"])},
      "access_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisionsöffnungen"])},
      "advanced_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zm Expertenmodus wechseln"])},
      "design_specification_window": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fenster “Designparameter” "])},
      "system_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info zu den Produktgruppen"])},
      "recyfix_hicap_slot_channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECYFIX HICAP Schlitzrinne"])},
      "system_installation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einbau des Systems"])},
      "design_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design-Support"])},
      "allow_flood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überstau zulassen"])}
    }
  }
}